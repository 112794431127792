/* stylelint-disable function-disallowed-list */
/* stylelint-disable color-no-hex */
$neutral-black: #1b1d22;
$neutral-200: #454a54;
$neutral-300: #585f6d;
$neutral-400: #666f7d;
$neutral-500: #7d8494;
$neutral-600: #959ca9;
$neutral-700: #b5b9c2;
$neutral-800: #d7d9de;
$neutral-850: #e3e5e8;
$neutral-900: #f5f6f8;
$neutral-950: #fbfbfc;
$neutral-white: #ffffff;

$tint-neutral-black-15-transparent: rgba($neutral-black, 0.15);
$tint-neutral-black-20-transparent: rgba($neutral-black, 0.2);
$tint-neutral-black-25-transparent: rgba($neutral-black, 0.25);
$tint-neutral-black-30-transparent: rgba($neutral-black, 0.3);
$tint-neutral-950-80-transparent: rgba($neutral-950, 0.8);

$primary-300: #0055cc;
$primary-400: #0c66e4;
$primary-500: #1d7afc;
$primary-600: #579dff;
$primary-700: #9ac4ff;
$primary-800: #cce0ff;
$primary-850: #e7f0ff;
$primary-900: #f2f7ff;

$primary-aqua-200: #206a83;
$primary-aqua-400: #2898bd;
$primary-aqua-500: #42b2d7;
$primary-aqua-600: #6cc3e0;
$primary-aqua-800: #c6edfb;
$primary-aqua-900: #edfbff;

$primary-teal-200: #216e4e;
$primary-teal-400: #22a06b;
$primary-teal-500: #2abb7f;
$primary-teal-600: #4bce97;
$primary-teal-800: #baf3db;
$primary-teal-900: #e0fff3;

$primary-yellow-200: #a16207;
$primary-yellow-400: #dca908;
$primary-yellow-500: #f0c82b;
$primary-yellow-600: #fde047;
$primary-yellow-700: #fef08a;
$primary-yellow-800: #fef9c3;
$primary-yellow-850: #fefbd9;
$primary-yellow-900: #fffded;

$primary-pink-200: #943d73;
$primary-pink-400: #cf5aa2;
$primary-pink-500: #da62ac;
$primary-pink-600: #e774bb;
$primary-pink-800: #fdd0ec;
$primary-pink-900: #fff2fa;

$primary-purple-200: #6f4db2;
$primary-purple-400: #9470db;
$primary-purple-500: #a17ee7;
$primary-purple-600: #af8fef;
$primary-purple-800: #e5d8fd;
$primary-purple-900: #f8f5ff;

$primary-violet-200: #4d5eb2;
$primary-violet-400: #7082db;
$primary-violet-500: #7f90e7;
$primary-violet-600: #8f9fef;
$primary-violet-800: #d8defd;
$primary-violet-900: #f5f6ff;

$semantic-error-200: #ae2424;
$semantic-error-300: #c92c2c;
$semantic-error-400: #db3b3b;
$semantic-error-500: #f15050;
$semantic-error-600: #f86868;
$semantic-error-700: #fd9191;
$semantic-error-800: #ffd2d2;
$semantic-error-850: #ffe4e4;
$semantic-error-900: #fff5f5;

$semantic-warning-200: #a55300;
$semantic-warning-400: #da740f;
$semantic-warning-500: #f3993f;
$semantic-warning-600: #feb062;
$semantic-warning-800: #fee2c8;
$semantic-warning-900: #fff6ed;

$semantic-success-200: #4c6b1f;
$semantic-success-400: #6a9a23;
$semantic-success-500: #82b536;
$semantic-success-600: #94c748;
$semantic-success-800: #d3f1a7;
$semantic-success-900: #effcd9;

$primary-color-dark-blue: #13296d;
$primary-color-vdw-blue: #2b4ec0;
$primary-color-highlight-blue: #4269e2;
$primary-color-lightblue: #9dc1ff;
$primary-color-vdw-pink: #e0669e;
$primary-color-grey: #737b92;
$primary-color-white: $neutral-white;

$primary-color-black: #101a43;
$primary-color-jute: #dc895b;
$primary-color-jute-new: #c26100;

$functional-color-red: #fa3d3d;
$functional-color-orange: #fa8b3d;
$functional-color-green: #0fba4c;
$functional-color-sat-purple: #7506e5;
$functional-color-sat-pink: #f07;
$functional-color-sat-turquoise: #019db8;
$functional-color-sat-brown: #86492c;

$primary-color-vdw-gradient: linear-gradient(221.58deg, $primary-color-vdw-blue 14.25%, #294ab7 25.87%, #1e388c 85.4%);

$tint-darkblue-70: #5a6a9a;
$tint-darkblue-70-transparent: rgba($primary-color-dark-blue, 0.7);
$tint-darkblue-60: #717fa7;
$tint-darkblue-50: #8a94b7;
$tint-darkblue-50-transparent: rgba($primary-color-dark-blue, 0.5);
$tint-darkblue-30: #b9bfd4;
$tint-darkblue-30-transparent: rgba($primary-color-dark-blue, 0.3);
$tint-darkblue-20: #d0d4e2; // main border color
$tint-darkblue-20-transparent: rgba($primary-color-dark-blue, 0.2);
$tint-darkblue-13-transparent: rgba($primary-color-dark-blue, 0.13);
$tint-darkblue-10-transparent: rgba($primary-color-dark-blue, 0.1);
$tint-darkblue-10: #e8eaf1;
$tint-darkblue-6: #f1f2f6;
$tint-darkblue-6-transparent: rgba($primary-color-dark-blue, 0.06);
$tint-darkblue-2: #fafbfc;
$tint-darkblue-2-transparent: rgba($primary-color-dark-blue, 0.02);

$tint-vdw-blue-hover: #223e9a;
$tint-vdw-blue-70: #6b84d3;
$tint-vdw-blue-70-transparent: rgba($primary-color-vdw-blue, 0.7);
$tint-vdw-blue-60: #8095d9;
$tint-vdw-blue-50: #95a7e0;
$tint-vdw-blue-40-transparent: rgba($primary-color-vdw-blue, 0.4);
$tint-vdw-blue-30: #c0caed;
$tint-vdw-blue-30-transparent: rgba($primary-color-vdw-blue, 0.3);
$tint-vdw-blue-20: #d5dcf2;
$tint-vdw-blue-20-transparent: rgba($primary-color-vdw-blue, 0.2);
$tint-vdw-blue-10: #eaeef9;
$tint-vdw-blue-10-transparent: rgba($primary-color-vdw-blue, 0.1);
$tint-vdw-blue-6: #f3f5fc;
$tint-vdw-blue-6-transparent: rgba($primary-color-vdw-blue, 0.06);

$tint-black-50: #888da1;
$tint-black-50-transparent: rgba($primary-color-black, 0.5);
$tint-black-40-transparent: rgba($primary-color-black, 0.4);
$tint-black-30-transparent: rgba($primary-color-black, 0.3);
$tint-black-20: #cfd1d9;
$tint-black-20-transparent: rgba($primary-color-black, 0.2);
$tint-black-10-transparent: rgba($primary-color-black, 0.1);

$tint-lightblue-70: #bbd4ff;
$tint-lightblue-40-transparent: rgba($primary-color-lightblue, 0.4);
$tint-lightblue-30: #e2edff;
$tint-lightblue-30-transparent: rgba($primary-color-lightblue, 0.3);
$tint-lightblue-20: #ebf3ff;
$tint-lightblue-20-transparent: rgba($primary-color-lightblue, 0.2);
$tint-lightblue-10: #f6f9ff;
$tint-lightblue-10-transparent: rgba($primary-color-lightblue, 0.1);
$tint-lightblue-6: #f9fcff;

$tint-red-70: #fc7777;
$tint-red-70-transparent: rgba($functional-color-red, 0.7);
$tint-red-50: #fd9e9e;
$tint-red-50-transparent: rgba($functional-color-red, 0.5);
$tint-red-30: #fec5c5;
$tint-red-30-transparent: rgba($functional-color-red, 0.3);
$tint-red-20: #fed8d8;
$tint-red-20-transparent: rgba($functional-color-red, 0.2);
$tint-red-10: #ffecec;
$tint-red-10-transparent: rgba($functional-color-red, 0.1);
$tint-red-6: #fff4f4;
$tint-red-6-transparent: rgba($functional-color-red, 0.06);

$tint-orange-70: #fcae78;
$tint-orange-50: #fdc59e;
$tint-orange-20: #fee8d8;
$tint-orange-20-transparent: rgba($functional-color-orange, 0.2);
$tint-orange-10: #fff4ec;
$tint-orange-10-transparent: rgba($functional-color-orange, 0.1);
$tint-orange-6: #fff8f4;

$tint-green-70: #57cf85;
$tint-green-50: #87dda6;
$tint-green-20: #cff1db;
$tint-green-20-transparent: rgba($functional-color-green, 0.2);
$tint-green-10: #e7f9ee;
$tint-green-6: #f1fbf4;

$tint-pink-70: #ea94bc;
$tint-pink-50: #f0b3cf;
$tint-pink-20: #f9e0ec;
$tint-pink-10: #fcf0f6;
$tint-pink-6: #fdf6f9;

$planning-soft-orange: #ffcab9;
$planning-soft-yellow: #fcecc5;
$planning-sky-blue: #9ae1ff;
$planning-bright-sky-blue: #b2f4f8;
$planning-baby-blue: #e2f5ff;
$planning-bright-pink: #f2aefe;
$planning-soft-pink: #fcceec;
$planning-soft-purple: #ded7f3;
$planning-soft-blue: #c4cded;
$planning-soft-grey: #e7e6ee;

$tint-white-90-transparent: rgba($primary-color-white, 0.9);
$tint-white-7-transparent: rgba($primary-color-white, 0.07);

$tint-jute-20-transparent: rgba($primary-color-jute, 0.2);
