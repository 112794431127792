@use 'variables' as *;

@mixin sidebar {
  .mat-sidenav {
    &.sidebar-small,
    &.sidebar-large {
      box-shadow: -20px 0 40px $tint-vdw-blue-10-transparent;
    }

    &.sidebar-small .mat-drawer-inner-container {
      width: 408px;
    }

    &.sidebar-medium .mat-drawer-inner-container {
      width: 512px;

      @include breakpoint-xsmall() {
        width: 100%;
      }
    }

    &.sidebar-large .mat-drawer-inner-container {
      width: 708px;
    }

    .sidebar-header {
      height: $card-header-height;
      padding: 0 24px;
      border-bottom: 1px solid $tint-darkblue-20;

      .sidebar-header-icon {
        height: 36px;
        width: 36px;
      }

      .sidebar-header-title {
        line-height: $card-header-height;
      }

      &.sidebar-header-with-back-button {
        padding-left: 12px;
      }
    }

    .sidebar-search {
      background-color: $tint-darkblue-6-transparent;
      box-shadow: inset 0 -1px 0 $tint-darkblue-20;

      .search-bar {
        padding: 16px 24px;
      }
    }

    .mat-toolbar {
      background: $primary-color-white;
    }

    @include breakpoint-xsmall() {
      width: 100%;

      &.sidebar-small .mat-drawer-inner-container {
        width: 100%;
      }
    }
  }

  .sidebar-container-without-toolbar {
    height: calc(100% - 68px);
  }

  .sidebar-container-with-toolbar {
    height: calc(100% - 128px);
  }

  .sidebar-container-with-toolbar,
  .sidebar-container-without-toolbar {
    overflow-y: auto;
    border-width: 0 1px 1px;
    border-color: $tint-darkblue-20;
    border-style: solid;
    border-radius: 0 0 6px 6px;
  }

  @media (min-width: $breakpoint-gt-xs) {
    .sidenav-border {
      border: 1px solid $tint-darkblue-20;
      border-radius: 6px;
    }
  }
}
