/* stylelint-disable function-disallowed-list */
svg.txc-svg {
  background: rgb(255, 255, 255);
}

$txc-svg-v2-color-chore-1: rgb(154, 7, 7);
$txc-svg-v2-color-chore-2: rgb(232, 217, 176);
$txc-svg-v2-color-chore-3: rgb(0, 0, 30);
$txc-svg-v2-color-chore-4: rgb(69, 104, 135);
$txc-svg-v2-color-chore-5: rgb(186, 132, 71);
$txc-svg-v2-color-chore-6: rgb(70, 122, 105);
$txc-svg-v2-color-chore-7: rgb(200, 14, 98);
$txc-svg-v2-color-chore-8: rgb(255, 228, 1);
$txc-svg-v2-color-chore-9: rgb(43, 133, 61);
$txc-svg-v2-color-chore-10: rgb(238, 166, 215);
$txc-svg-v2-color-chore-11: rgb(58, 49, 135);
$txc-svg-v2-color-chore-12: rgb(13, 249, 255);
$txc-svg-v2-color-chore-13: rgb(229, 107, 21);
$txc-svg-v2-color-chore-14: rgb(205, 143, 61);
$txc-svg-v2-color-chore-15: rgb(201, 14, 123);
$txc-svg-v2-color-chore-16: rgb(110, 137, 156);
$txc-svg-v2-color-chore-17: rgb(111, 119, 2);
$txc-svg-v2-color-chore-18: rgb(252, 105, 85);
$txc-svg-v2-color-chore-19: rgb(126, 125, 205);
$txc-svg-v2-color-chore-20: rgb(216, 50, 70);
$txc-svg-v2-color-chore-21: rgb(210, 165, 0);
$txc-svg-v2-color-chore-22: rgb(0, 160, 234);
$txc-svg-v2-color-chore-23: rgb(122, 80, 56);
$txc-svg-v2-color-chore-24: rgb(196, 249, 38);

$txc-svg-v2-color-line: rgb(0, 0, 0);

$txc-svg-v2-color-weft: rgb(169, 169, 169);
$txc-svg-v2-color-dent-separator: rgb(0, 0, 0);
$txc-svg-v2-color-bottom-marker: rgb(169, 169, 169);
$txc-svg-v2-color-vertical-line-between-pick: rgb(169, 169, 169);
$txc-svg-v2-color-top-marker: rgb(169, 169, 169);
$txc-svg-v2-color-dent-nr: rgb(169, 169, 169);
$txc-svg-v2-color-color-line: rgb(169, 169, 169);
$txc-svg-v2-color-dent-warp: rgb(0, 0, 0);

circle.txc-svg-v2-weftfilled {
  stroke: $txc-svg-v2-color-weft;
  fill: $txc-svg-v2-color-weft;
}

.txc-svg-v2-dent-separatorfilled {
  stroke: none;
  fill: $txc-svg-v2-color-dent-separator;
  opacity: 0.05;
}

.txc-svg-v2-bottom-marker {
  stroke: $txc-svg-v2-color-bottom-marker;
}

.txc-svg-v2-vertical-line-between-pick {
  stroke: $txc-svg-v2-color-vertical-line-between-pick;
}

.txc-svg-v2-top-marker {
  stroke: $txc-svg-v2-color-top-marker;
}

text {
  font-family: Arial, Helvetica, sans-serif;
  stroke: none;
}

line {
  stroke: $txc-svg-v2-color-line;
  stroke-width: 1px;
}

text.txc-svg-v2-chore {
  stroke: none;
  font-size: 10px;
  font-weight: bold;
}

text.txc-svg-v2-dent-nr {
  fill: $txc-svg-v2-color-dent-nr;
  font-size: 14px;
}

text.txc-svg-v2-color-line {
  fill: $txc-svg-v2-color-color-line;
  font-size: 14px;
}

line.txc-svg-v2-chore,
polyline.txc-svg-v2-chore {
  fill: none;
  stroke-width: 1.25px;
}

line.txc-svg-v2-warp,
polyline.txc-svg-v2-warp {
  fill: none;
  stroke-width: 0.4px;
  stroke: $txc-svg-v2-color-dent-warp;
}

text.txc-svg-v2-warp-nr {
  stroke: none;
  font-size: 8px;
}

text.txc-svg-v2-chore-1 {
  fill: $txc-svg-v2-color-chore-1;
}

line.txc-svg-v2-chore-1,
polyline.txc-svg-v2-chore-1 {
  stroke: $txc-svg-v2-color-chore-1;
}

text.txc-svg-v2-chore-2 {
  fill: $txc-svg-v2-color-chore-2;
}

line.txc-svg-v2-chore-2,
polyline.txc-svg-v2-chore-2 {
  stroke: $txc-svg-v2-color-chore-2;
}

text.txc-svg-v2-chore-3 {
  fill: $txc-svg-v2-color-chore-3;
}

line.txc-svg-v2-chore-3,
polyline.txc-svg-v2-chore-3 {
  stroke: $txc-svg-v2-color-chore-3;
}

text.txc-svg-v2-chore-4 {
  fill: $txc-svg-v2-color-chore-4;
}

line.txc-svg-v2-chore-4,
polyline.txc-svg-v2-chore-4 {
  stroke: $txc-svg-v2-color-chore-4;
}

text.txc-svg-v2-chore-5 {
  fill: $txc-svg-v2-color-chore-5;
}

line.txc-svg-v2-chore-5,
polyline.txc-svg-v2-chore-5 {
  stroke: $txc-svg-v2-color-chore-5;
}

text.txc-svg-v2-chore-6 {
  fill: $txc-svg-v2-color-chore-6;
}

line.txc-svg-v2-chore-6,
polyline.txc-svg-v2-chore-6 {
  stroke: $txc-svg-v2-color-chore-6;
}

text.txc-svg-v2-chore-7 {
  fill: $txc-svg-v2-color-chore-7;
}

line.txc-svg-v2-chore-7,
polyline.txc-svg-v2-chore-7 {
  stroke: $txc-svg-v2-color-chore-7;
}

text.txc-svg-v2-chore-8 {
  fill: $txc-svg-v2-color-chore-8;
}

line.txc-svg-v2-chore-8,
polyline.txc-svg-v2-chore-8 {
  stroke: $txc-svg-v2-color-chore-8;
}

text.txc-svg-v2-chore-9 {
  fill: $txc-svg-v2-color-chore-9;
}

line.txc-svg-v2-chore-9,
polyline.txc-svg-v2-chore-9 {
  stroke: $txc-svg-v2-color-chore-9;
}

text.txc-svg-v2-chore-10 {
  fill: $txc-svg-v2-color-chore-10;
}

line.txc-svg-v2-chore-10,
polyline.txc-svg-v2-chore-10 {
  stroke: $txc-svg-v2-color-chore-10;
}

text.txc-svg-v2-chore-11 {
  fill: $txc-svg-v2-color-chore-11;
}

line.txc-svg-v2-chore-11,
polyline.txc-svg-v2-chore-11 {
  stroke: $txc-svg-v2-color-chore-11;
}

text.txc-svg-v2-chore-12 {
  fill: $txc-svg-v2-color-chore-12;
}

line.txc-svg-v2-chore-12,
polyline.txc-svg-v2-chore-12 {
  stroke: $txc-svg-v2-color-chore-12;
}

text.txc-svg-v2-chore-13 {
  fill: $txc-svg-v2-color-chore-13;
}

line.txc-svg-v2-chore-13,
polyline.txc-svg-v2-chore-13 {
  stroke: $txc-svg-v2-color-chore-13;
}

text.txc-svg-v2-chore-14 {
  fill: $txc-svg-v2-color-chore-14;
}

line.txc-svg-v2-chore-14,
polyline.txc-svg-v2-chore-14 {
  stroke: $txc-svg-v2-color-chore-14;
}

text.txc-svg-v2-chore-15 {
  fill: $txc-svg-v2-color-chore-15;
}

line.txc-svg-v2-chore-15,
polyline.txc-svg-v2-chore-15 {
  stroke: $txc-svg-v2-color-chore-15;
}

text.txc-svg-v2-chore-16 {
  fill: $txc-svg-v2-color-chore-16;
}

line.txc-svg-v2-chore-16,
polyline.txc-svg-v2-chore-16 {
  stroke: $txc-svg-v2-color-chore-16;
}

text.txc-svg-v2-chore-17 {
  fill: $txc-svg-v2-color-chore-17;
}

line.txc-svg-v2-chore-17,
polyline.txc-svg-v2-chore-17 {
  stroke: $txc-svg-v2-color-chore-17;
}

text.txc-svg-v2-chore-18 {
  fill: $txc-svg-v2-color-chore-18;
}

line.txc-svg-v2-chore-18,
polyline.txc-svg-v2-chore-18 {
  stroke: $txc-svg-v2-color-chore-18;
}

text.txc-svg-v2-chore-19 {
  fill: $txc-svg-v2-color-chore-19;
}

line.txc-svg-v2-chore-19,
polyline.txc-svg-v2-chore-19 {
  stroke: $txc-svg-v2-color-chore-19;
}

text.txc-svg-v2-chore-20 {
  fill: $txc-svg-v2-color-chore-20;
}

line.txc-svg-v2-chore-20,
polyline.txc-svg-v2-chore-20 {
  stroke: $txc-svg-v2-color-chore-20;
}

text.txc-svg-v2-chore-21 {
  fill: $txc-svg-v2-color-chore-21;
}

line.txc-svg-v2-chore-21,
polyline.txc-svg-v2-chore-21 {
  stroke: $txc-svg-v2-color-chore-21;
}

text.txc-svg-v2-chore-22 {
  fill: $txc-svg-v2-color-chore-22;
}

line.txc-svg-v2-chore-22,
polyline.txc-svg-v2-chore-22 {
  stroke: $txc-svg-v2-color-chore-22;
}

text.txc-svg-v2-chore-23 {
  fill: $txc-svg-v2-color-chore-23;
}

line.txc-svg-v2-chore-23,
polyline.txc-svg-v2-chore-23 {
  stroke: $txc-svg-v2-color-chore-23;
}

text.txc-svg-v2-chore-24 {
  fill: $txc-svg-v2-color-chore-24;
}

line.txc-svg-v2-chore-24,
polyline.txc-svg-v2-chore-24 {
  stroke: $txc-svg-v2-color-chore-24;
}

$txc-svg-manchonv1-color-manchon-0-fill: rgb(255, 255, 255);
$txc-svg-manchonv1-color-manchon-0-stroke: rgb(0, 0, 0);

$txc-svg-manchonv1-color-manchon-1-fill: rgb(0, 0, 150);
$txc-svg-manchonv1-color-manchon-1-stroke: rgb(0, 0, 0);

$txc-svg-manchonv1-color-brightness-0-fill: rgb(0, 0, 150);
$txc-svg-manchonv1-color-brightness-1-fill: rgb(0, 160, 255);
$txc-svg-manchonv1-color-brightness-2-fill: rgb(0, 80, 200);

$txc-svg-manchonv1-color-thread-nr-fill: rgb(113, 113, 113);
$txc-svg-manchonv1-color-thread-separator-stroke: rgb(0, 0, 0);
$txc-svg-manchonv1-color-working-chore-fill: rgb(169, 169, 169);
$txc-svg-manchonv1-color-top-bottom-indicator-stroke: rgb(198, 198, 198);
$txc-svg-manchonv1-color-color-line-separator-stroke: rgb(0, 0, 0);
$txc-svg-manchonv1-color-dent-separator-stroke: rgb(0, 0, 0);
$txc-svg-manchonv1-color-dent-nr-fill: rgb(0, 0, 0);
$txc-svg-manchonv1-color-pick-nr-fill: rgb(113, 113, 113);
$txc-svg-manchonv1-color-pick-separator-stroke: rgb(0, 0, 0);
$txc-svg-manchonv1-color-color-line-nr-fill: rgb(0, 0, 0);

polygon.txc-svg-manchonv1-manchon-0 {
  fill: $txc-svg-manchonv1-color-manchon-0-fill;
  stroke: $txc-svg-manchonv1-color-manchon-0-stroke;
  stroke-width: 0.3px;
}

polygon.txc-svg-manchonv1-manchon-1 {
  fill: $txc-svg-manchonv1-color-manchon-1-fill;
  stroke: $txc-svg-manchonv1-color-manchon-1-stroke;
  stroke-width: 0.3px;
}

polygon.txc-svg-manchonv1-brightness-0 {
  fill: $txc-svg-manchonv1-color-brightness-0-fill;
}

polygon.txc-svg-manchonv1-brightness-1 {
  fill: $txc-svg-manchonv1-color-brightness-1-fill;
}

polygon.txc-svg-manchonv1-brightness-2 {
  fill: $txc-svg-manchonv1-color-brightness-2-fill;
}

text.txc-svg-manchonv1-thread-nr {
  fill: $txc-svg-manchonv1-color-thread-nr-fill;
  font-size: 13px;
}

polygon.txc-svg-manchonv1-working-chore {
  fill: $txc-svg-manchonv1-color-working-chore-fill;
  opacity: 0.4;
}

line.txc-svg-manchonv1-thread-separator {
  stroke: $txc-svg-manchonv1-color-thread-separator-stroke;
}

line.txc-svg-manchonv1-top-bottom-indicator {
  stroke: $txc-svg-manchonv1-color-top-bottom-indicator-stroke;
  stroke-width: 2px;
}

line.txc-svg-manchonv1-color-line-separator {
  stroke: $txc-svg-manchonv1-color-color-line-separator-stroke;
  stroke-width: 2px;
}

line.txc-svg-manchonv1-dent-separator {
  stroke: $txc-svg-manchonv1-color-dent-separator-stroke;
  stroke-width: 2px;
}

text.txc-svg-manchonv1-dent-nr {
  fill: $txc-svg-manchonv1-color-dent-nr-fill;
  font-weight: bold;
  font-size: 16px;
}

text.txc-svg-manchonv1-pick-nr {
  fill: $txc-svg-manchonv1-color-pick-nr-fill;
  font-size: 12px;
}

line.txc-svg-manchonv1-pick-separator {
  stroke: $txc-svg-manchonv1-color-pick-separator-stroke;
  stroke-width: 1px;
}

text.txc-svg-manchonv1-color-line-nr {
  font-size: 16px;
  font-weight: bold;
  fill: $txc-svg-manchonv1-color-color-line-nr-fill;
}

$txc-svg-circle-opacity: 1;
$txc-svg-chore1-color: rgb(255, 214, 0);
$txc-svg-chore10-color: rgb(8, 242, 214);
$txc-svg-chore11-color: rgb(27, 8, 242);
$txc-svg-chore12-color: rgb(8, 242, 151);
$txc-svg-chore13-color: rgb(255, 214, 0);
$txc-svg-chore14-color: rgb(254, 84, 0);
$txc-svg-chore15-color: rgb(242, 8, 149);
$txc-svg-chore16-color: rgb(0, 252, 56);
$txc-svg-weft1-color: rgb(12, 21, 240);
$txc-svg-weft2-color: rgb(231, 8, 242);
$txc-svg-weft3-color: rgb(242, 15, 8);
$txc-svg-chore2-color: rgb(254, 84, 0);
$txc-svg-weft4-color: rgb(242, 135, 8);
$txc-svg-weft5-color: rgb(8, 242, 214);
$txc-svg-weft6-color: rgb(27, 8, 242);
$txc-svg-weft7-color: rgb(8, 242, 151);
$txc-svg-weft8-color: rgb(255, 214, 0);
$txc-svg-chore3-color: rgb(242, 8, 149);
$txc-svg-chore4-color: rgb(0, 252, 56);
$txc-svg-chore5-color: rgb(12, 21, 240);
$txc-svg-chore6-color: rgb(231, 8, 242);
$txc-svg-chore7-color: rgb(242, 15, 8);
$txc-svg-chore8-color: rgb(125, 8, 242);
$txc-svg-chore9-color: rgb(242, 135, 8);

$txc-svg-layer1-color: rgb(12, 21, 240);
$txc-svg-layer3-color: rgb(242, 15, 8);
$txc-svg-layer2-color: rgb(254, 84, 0);
$txc-svg-layer4-color: rgb(242, 135, 8);
$txc-svg-layer5-color: rgb(8, 242, 214);
$txc-svg-layer6-color: rgb(27, 8, 242);
$txc-svg-layer7-color: rgb(8, 242, 151);
$txc-svg-layer8-color: rgb(255, 214, 0);

$txc-svg-layer500-color: rgb(255, 214, 0);
$txc-svg-layer501-color: rgb(254, 84, 0);
$txc-svg-layer502-color: rgb(242, 8, 149);
$txc-svg-layer503-color: rgb(0, 252, 56);
$txc-svg-layer504-color: rgb(12, 21, 240);
$txc-svg-layer505-color: rgb(231, 8, 242);
$txc-svg-layer506-color: rgb(242, 15, 8);
$txc-svg-layer990-color: rgb(127, 127, 127);
$txc-svg-layer999-color: rgb(64, 64, 64);
$txc-svg-chore999-color: rgb(127, 127, 127);
$txc-svg-chore990-color: rgb(255, 214, 0);

$txc-svg-polygon-opacity: 0.4;
$txc-svg-stroke-width: 2;
$txc-svg-font: verdana;
$txc-svg-font-size: 9px;

text.txc-svg-chore1 {
  fill: $txc-svg-chore1-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore1 {
  fill: none;
  stroke: $txc-svg-chore1-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore1 {
  fill: none;
  stroke: $txc-svg-chore1-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore1filled {
  fill: $txc-svg-chore1-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore1filled {
  fill: $txc-svg-chore1-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore1 {
  stroke: $txc-svg-chore1-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore2 {
  fill: $txc-svg-chore2-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore2 {
  fill: none;
  stroke: $txc-svg-chore2-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore2 {
  fill: none;
  stroke: $txc-svg-chore2-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore2filled {
  fill: $txc-svg-chore2-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore2filled {
  fill: $txc-svg-chore2-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore2 {
  stroke: $txc-svg-chore2-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore3 {
  fill: $txc-svg-chore3-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore3 {
  fill: none;
  stroke: $txc-svg-chore3-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore3 {
  fill: none;
  stroke: $txc-svg-chore3-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore3filled {
  fill: $txc-svg-chore3-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore3filled {
  fill: $txc-svg-chore3-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore3 {
  stroke: $txc-svg-chore3-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore4 {
  fill: $txc-svg-chore4-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore4 {
  fill: none;
  stroke: $txc-svg-chore4-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore4 {
  fill: none;
  stroke: $txc-svg-chore4-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore4filled {
  fill: $txc-svg-chore4-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore4filled {
  fill: $txc-svg-chore4-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore4 {
  stroke: $txc-svg-chore4-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore5 {
  fill: $txc-svg-chore5-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore5 {
  fill: none;
  stroke: $txc-svg-chore5-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore5 {
  fill: none;
  stroke: $txc-svg-chore5-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore5filled {
  fill: $txc-svg-chore5-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore5filled {
  fill: $txc-svg-chore5-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore5 {
  stroke: $txc-svg-chore5-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore6 {
  fill: $txc-svg-chore6-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore6 {
  fill: none;
  stroke: $txc-svg-chore6-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore6 {
  fill: none;
  stroke: $txc-svg-chore6-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore6filled {
  fill: $txc-svg-chore6-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore6filled {
  fill: $txc-svg-chore6-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore6 {
  stroke: $txc-svg-chore6-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore7 {
  fill: $txc-svg-chore7-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore7 {
  fill: none;
  stroke: $txc-svg-chore7-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore7 {
  fill: none;
  stroke: $txc-svg-chore7-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore7filled {
  fill: $txc-svg-chore7-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore7filled {
  fill: $txc-svg-chore7-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore7 {
  stroke: $txc-svg-chore7-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore8 {
  fill: $txc-svg-chore8-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore8 {
  fill: none;
  stroke: $txc-svg-chore8-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore8 {
  fill: none;
  stroke: $txc-svg-chore8-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore8filled {
  fill: $txc-svg-chore8-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore8filled {
  fill: $txc-svg-chore8-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore8 {
  stroke: $txc-svg-chore8-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore9 {
  fill: $txc-svg-chore9-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore9 {
  fill: none;
  stroke: $txc-svg-chore9-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore9 {
  fill: none;
  stroke: $txc-svg-chore9-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore9filled {
  fill: $txc-svg-chore9-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore9filled {
  fill: $txc-svg-chore9-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore9 {
  stroke: $txc-svg-chore9-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore10 {
  fill: $txc-svg-chore10-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore10 {
  fill: none;
  stroke: $txc-svg-chore10-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore10 {
  fill: none;
  stroke: $txc-svg-chore10-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore10filled {
  fill: $txc-svg-chore10-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore10filled {
  fill: $txc-svg-chore10-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore10 {
  stroke: $txc-svg-chore10-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore11 {
  fill: $txc-svg-chore11-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore11 {
  fill: none;
  stroke: $txc-svg-chore11-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore11 {
  fill: none;
  stroke: $txc-svg-chore11-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore11filled {
  fill: $txc-svg-chore11-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore11filled {
  fill: $txc-svg-chore11-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore11 {
  stroke: $txc-svg-chore11-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore12 {
  fill: $txc-svg-chore12-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore12 {
  fill: none;
  stroke: $txc-svg-chore12-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore12 {
  fill: none;
  stroke: $txc-svg-chore12-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore12filled {
  fill: $txc-svg-chore12-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore12filled {
  fill: $txc-svg-chore12-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore12 {
  stroke: $txc-svg-chore12-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore13 {
  fill: $txc-svg-chore13-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore13 {
  fill: none;
  stroke: $txc-svg-chore13-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore13 {
  fill: none;
  stroke: $txc-svg-chore13-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore13filled {
  fill: $txc-svg-chore13-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore13filled {
  fill: $txc-svg-chore13-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore13 {
  stroke: $txc-svg-chore13-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore14 {
  fill: $txc-svg-chore14-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore14 {
  fill: none;
  stroke: $txc-svg-chore14-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore14 {
  fill: none;
  stroke: $txc-svg-chore14-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore14filled {
  fill: $txc-svg-chore14-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore14filled {
  fill: $txc-svg-chore14-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore14 {
  stroke: $txc-svg-chore14-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore15 {
  fill: $txc-svg-chore15-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore15 {
  fill: none;
  stroke: $txc-svg-chore15-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore15 {
  fill: none;
  stroke: $txc-svg-chore15-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore15filled {
  fill: $txc-svg-chore15-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore15filled {
  fill: $txc-svg-chore15-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore15 {
  stroke: $txc-svg-chore15-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore16 {
  fill: $txc-svg-chore16-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore16 {
  fill: none;
  stroke: $txc-svg-chore16-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore16 {
  fill: none;
  stroke: $txc-svg-chore16-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore16filled {
  fill: $txc-svg-chore16-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore16filled {
  fill: $txc-svg-chore16-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore16 {
  stroke: $txc-svg-chore16-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-weft1 {
  fill: $txc-svg-weft1-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-weft1 {
  fill: none;
  stroke: $txc-svg-weft1-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-weft1 {
  fill: none;
  stroke: $txc-svg-weft1-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-weft1filled {
  fill: $txc-svg-weft1-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-weft1filled {
  fill: $txc-svg-weft1-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-weft1 {
  stroke: $txc-svg-weft1-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-weft2 {
  fill: $txc-svg-weft2-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-weft2 {
  fill: none;
  stroke: $txc-svg-weft2-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-weft2 {
  fill: none;
  stroke: $txc-svg-weft2-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-weft2filled {
  fill: $txc-svg-weft2-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-weft2filled {
  fill: $txc-svg-weft2-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-weft2 {
  stroke: $txc-svg-weft2-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-weft3 {
  fill: $txc-svg-weft3-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-weft3 {
  fill: none;
  stroke: $txc-svg-weft3-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-weft3 {
  fill: none;
  stroke: $txc-svg-weft3-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-weft3filled {
  fill: $txc-svg-weft3-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-weft3filled {
  fill: $txc-svg-weft3-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-weft3 {
  stroke: $txc-svg-weft3-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-weft4 {
  fill: $txc-svg-weft4-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-weft4 {
  fill: none;
  stroke: $txc-svg-weft4-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-weft4 {
  fill: none;
  stroke: $txc-svg-weft4-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-weft4filled {
  fill: $txc-svg-weft4-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-weft4filled {
  fill: $txc-svg-weft4-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-weft4 {
  stroke: $txc-svg-weft4-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-weft5 {
  fill: $txc-svg-weft5-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-weft5 {
  fill: none;
  stroke: $txc-svg-weft5-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-weft5 {
  fill: none;
  stroke: $txc-svg-weft5-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-weft5filled {
  fill: $txc-svg-weft5-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-weft5filled {
  fill: $txc-svg-weft5-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-weft5 {
  stroke: $txc-svg-weft5-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-weft6 {
  fill: $txc-svg-weft6-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-weft6 {
  fill: none;
  stroke: $txc-svg-weft6-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-weft6 {
  fill: none;
  stroke: $txc-svg-weft6-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-weft6filled {
  fill: $txc-svg-weft6-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-weft6filled {
  fill: $txc-svg-weft6-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-weft6 {
  stroke: $txc-svg-weft6-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-weft7 {
  fill: $txc-svg-weft7-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-weft7 {
  fill: none;
  stroke: $txc-svg-weft7-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-weft7 {
  fill: none;
  stroke: $txc-svg-weft7-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-weft7filled {
  fill: $txc-svg-weft7-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-weft7filled {
  fill: $txc-svg-weft7-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-weft7 {
  stroke: $txc-svg-weft7-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-weft8 {
  fill: $txc-svg-weft8-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-weft8 {
  fill: none;
  stroke: $txc-svg-weft8-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-weft8 {
  fill: none;
  stroke: $txc-svg-weft8-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-weft8filled {
  fill: $txc-svg-weft8-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-weft8filled {
  fill: $txc-svg-weft8-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-weft8 {
  stroke: $txc-svg-weft8-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore990 {
  fill: $txc-svg-chore990-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore990 {
  fill: none;
  stroke: $txc-svg-chore990-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore990 {
  fill: none;
  stroke: $txc-svg-chore990-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore990filled {
  fill: $txc-svg-chore990-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore990filled {
  fill: $txc-svg-chore990-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore990 {
  stroke: $txc-svg-chore990-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore999 {
  fill: $txc-svg-chore999-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore999 {
  fill: none;
  stroke: $txc-svg-chore999-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore999 {
  fill: none;
  stroke: $txc-svg-chore999-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore999filled {
  fill: $txc-svg-chore999-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore999filled {
  fill: $txc-svg-chore999-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore999 {
  stroke: $txc-svg-chore999-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-layer500 {
  fill: $txc-svg-layer500-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-layer500 {
  fill: none;
  stroke: $txc-svg-layer500-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-layer500 {
  fill: none;
  stroke: $txc-svg-layer500-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-layer500filled {
  fill: $txc-svg-layer500-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-layer500filled {
  fill: $txc-svg-layer500-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-layer500 {
  stroke: $txc-svg-layer500-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-layer504 {
  fill: $txc-svg-layer504-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-layer504 {
  fill: none;
  stroke: $txc-svg-layer504-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-layer504 {
  fill: none;
  stroke: $txc-svg-layer504-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-layer504filled {
  fill: $txc-svg-layer504-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-layer504filled {
  fill: $txc-svg-layer504-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-layer504 {
  stroke: $txc-svg-layer504-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-layer1 {
  fill: $txc-svg-layer1-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-layer1 {
  fill: none;
  stroke: $txc-svg-layer1-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-layer1 {
  fill: none;
  stroke: $txc-svg-layer1-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-layer1filled {
  fill: $txc-svg-layer1-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-layer1filled {
  fill: $txc-svg-layer1-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-layer1 {
  stroke: $txc-svg-layer1-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-layer2 {
  fill: $txc-svg-layer2-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-layer2 {
  fill: none;
  stroke: $txc-svg-layer2-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-layer2 {
  fill: none;
  stroke: $txc-svg-layer2-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-layer2filled {
  fill: $txc-svg-layer2-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-layer2filled {
  fill: $txc-svg-layer2-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-layer2 {
  stroke: $txc-svg-layer2-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-layer3 {
  fill: $txc-svg-layer3-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-layer3 {
  fill: none;
  stroke: $txc-svg-layer3-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-layer3 {
  fill: none;
  stroke: $txc-svg-layer3-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-layer3filled {
  fill: $txc-svg-layer3-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-layer3filled {
  fill: $txc-svg-layer3-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-layer3 {
  stroke: $txc-svg-layer3-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-layer4 {
  fill: $txc-svg-layer4-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-layer4 {
  fill: none;
  stroke: $txc-svg-layer4-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-layer4 {
  fill: none;
  stroke: $txc-svg-layer4-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-layer4filled {
  fill: $txc-svg-layer4-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-layer4filled {
  fill: $txc-svg-layer4-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-layer4 {
  stroke: $txc-svg-layer4-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-layer5 {
  fill: $txc-svg-layer5-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-layer5 {
  fill: none;
  stroke: $txc-svg-layer5-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-layer5 {
  fill: none;
  stroke: $txc-svg-layer5-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-layer5filled {
  fill: $txc-svg-layer5-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-layer5filled {
  fill: $txc-svg-layer5-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-layer5 {
  stroke: $txc-svg-layer5-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-layer6 {
  fill: $txc-svg-layer6-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-layer6 {
  fill: none;
  stroke: $txc-svg-layer6-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-layer6 {
  fill: none;
  stroke: $txc-svg-layer6-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-layer6filled {
  fill: $txc-svg-layer6-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-layer6filled {
  fill: $txc-svg-layer6-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-layer6 {
  stroke: $txc-svg-layer6-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-layer7 {
  fill: $txc-svg-layer7-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-layer7 {
  fill: none;
  stroke: $txc-svg-layer7-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-layer7 {
  fill: none;
  stroke: $txc-svg-layer7-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-layer7filled {
  fill: $txc-svg-layer7-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-layer7filled {
  fill: $txc-svg-layer7-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-layer7 {
  stroke: $txc-svg-layer7-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-layer8 {
  fill: $txc-svg-layer8-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-layer8 {
  fill: none;
  stroke: $txc-svg-layer8-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-layer8 {
  fill: none;
  stroke: $txc-svg-layer8-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-layer8filled {
  fill: $txc-svg-layer8-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-layer8filled {
  fill: $txc-svg-layer8-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-layer8 {
  stroke: $txc-svg-layer8-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-layer999 {
  fill: $txc-svg-layer999-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-layer999 {
  fill: none;
  stroke: $txc-svg-layer999-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-layer999 {
  fill: none;
  stroke: $txc-svg-layer999-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-layer999filled {
  fill: $txc-svg-layer999-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-layer999filled {
  fill: $txc-svg-layer999-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-layer999 {
  stroke: $txc-svg-layer999-color;
  stroke-width: $txc-svg-stroke-width;
}
