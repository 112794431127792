@mixin breakpoint-handset-landscape {
  @media (max-width: 959.98px) and (orientation: landscape) and (min-resolution: 1.5dppx) {
    @content;
  }
}

@mixin breakpoint-xsmall {
  @media (max-width: 599.98px) {
    @content;
  }
}
