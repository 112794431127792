@use '../vdw-variables' as *;

@mixin tab-group {
  .mdc-tab,
  .mat-mdc-tab-link {
    --mat-tab-header-label-text-tracking: -0.05px;
    --mat-tab-header-inactive-label-text-color: #{$neutral-black};
    --mat-tab-header-inactive-hover-label-text-color: #{$primary-400};
    --mat-tab-header-inactive-focus-label-text-color: #{$primary-400};
    --mat-tab-header-inactive-ripple-color: #{$primary-900};
    --mat-tab-header-active-label-text-color: #{$primary-400};
    --mat-tab-header-active-hover-label-text-color: #{$primary-400};
    --mat-tab-header-active-focus-label-text-color: #{$primary-400};
    --mat-tab-header-active-hover-indicator-color: #{$primary-400};
    --mat-tab-header-active-focus-indicator-color: #{$primary-400};
    --mat-tab-header-active-ripple-color: #{$primary-900};
    --mdc-tab-indicator-active-indicator-color: #{$primary-400};

    min-width: 0 !important;
    padding: 4px 0 12px !important;

    .mat-mdc-tab-ripple {
      display: none;
    }

    .mdc-tab__ripple::before {
      top: 4px !important;
      bottom: 12px !important;
      border-radius: 4px;
    }

    &:hover:not(.mat-mdc-tab-disabled) .mdc-tab__ripple::before {
      opacity: 1;
    }

    &:active:not(.mat-mdc-tab-disabled) .mdc-tab__ripple::before {
      --mat-tab-header-inactive-ripple-color: #{$primary-850};
      --mat-tab-header-active-ripple-color: #{$primary-850};
    }

    .mdc-tab__content {
      height: auto !important;
      padding: 8px 10px;

      .mdc-tab__text-label {
        font-family: $mat-font-family;
        font-size: 14px;
        font-weight: $font-weight-regular;
      }
    }

    &.tab-error {
      --mat-tab-header-inactive-label-text-color: #{$semantic-error-400};
      --mat-tab-header-inactive-hover-label-text-color: #{$semantic-error-400};
      --mat-tab-header-inactive-focus-label-text-color: #{$semantic-error-400};
      --mat-tab-header-inactive-ripple-color: #{$semantic-error-900};
      --mat-tab-header-active-label-text-color: #{$semantic-error-400};
      --mat-tab-header-active-hover-label-text-color: #{$semantic-error-400};
      --mat-tab-header-active-focus-label-text-color: #{$semantic-error-400};
      --mat-tab-header-active-hover-indicator-color: #{$semantic-error-400};
      --mat-tab-header-active-focus-indicator-color: #{$semantic-error-400};
      --mat-tab-header-active-ripple-color: #{$semantic-error-900};
      --mdc-tab-indicator-active-indicator-color: #{$semantic-error-400};

      &:active:not(.mat-mdc-tab-disabled) .mdc-tab__ripple::before {
        --mat-tab-header-inactive-ripple-color: #{$semantic-error-850};
        --mat-tab-header-active-ripple-color: #{$semantic-error-850};
      }
    }

    &.mat-mdc-tab-disabled {
      --mat-tab-header-inactive-label-text-color: #{$neutral-500};

      opacity: 1 !important;

      .mdc-tab__content {
        pointer-events: none;
      }
    }
  }

  .mat-mdc-tab-group {
    border: 1px solid $tint-darkblue-20;
    border-radius: 4px;

    .mat-mdc-tab-header {
      height: 56px;
      border-bottom: 1px solid $tint-darkblue-20;

      .mat-mdc-tab-labels,
      .mdc-tab {
        height: 100%;
      }

      .mdc-tab__text-label {
        font-size: 16px;
      }
    }
  }
}
