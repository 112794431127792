.no-pointer-events {
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: grab;
}

.cursor-default {
  cursor: default;

  .ag-header-cell-label {
    cursor: default;
  }
}
