/* stylelint-disable selector-max-compound-selectors */
@use '../vdw-variables' as *;

@mixin forms {
  :root {
    --mdc-typography-caption-letter-spacing: -0.05px;
    --mat-form-field-container-text-tracking: -0.05px;
    --mdc-filled-text-field-label-text-tracking: -0.05px;
    --mat-option-label-text-tracking: -0.05px;
    --mat-select-trigger-text-tracking: -0.05px;
    --mat-form-field-subscript-text-tracking: -0.05px;
  }

  input {
    letter-spacing: inherit;
  }

  .mdc-form-field {
    --mdc-form-field-label-text-tracking: -0.05px;

    font-family: $mat-font-family !important;
  }

  .mat-mdc-form-field {
    max-width: calc(100% - 0.5px);

    .mat-mdc-text-field-wrapper {
      background-color: transparent !important;
      padding: 0;
      border-radius: 0;

      .mat-mdc-form-field-focus-overlay,
      .mdc-line-ripple {
        display: none;
      }

      .mat-mdc-form-field-flex {
        height: 44px;
        margin-top: 22px;

        .mat-mdc-floating-label {
          top: -24px;
          transform: none;

          mat-label {
            width: 100%;
            font-size: 13px;
            font-weight: $font-weight-regular;
            color: $primary-color-dark-blue;
            overflow: hidden;
            text-overflow: ellipsis;

            .optional {
              color: $tint-darkblue-50;
              padding-left: 4px !important;
            }
          }

          .mat-mdc-form-field-required-marker {
            display: none;
          }
        }

        .mat-mdc-form-field-infix {
          padding: 0;
          min-height: 44px;
          height: 100%;
          border-radius: 3px;
          background-color: $tint-darkblue-2;
          border: 1px solid $tint-darkblue-20;

          .mat-mdc-input-element {
            height: 100%;
            width: 100%;
            padding: 12px 16px;
            caret-color: $primary-color-vdw-blue;
            color: $primary-color-dark-blue;

            &::placeholder {
              color: $tint-darkblue-50;
            }

            &[type='number'] {
              -moz-appearance: textfield;
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
            }
          }

          .mat-mdc-form-field-textarea-control.not-resizable {
            resize: none;
          }
        }

        .mat-mdc-form-field-icon-prefix {
          position: absolute;
          left: 0;
          padding-left: 16px;
          z-index: 1;

          .mdc-icon-button {
            background: transparent !important;
          }

          &:has(.mat-datepicker-toggle),
          &:has(.mdc-icon-button) {
            padding-left: 8px;
          }
        }

        .mat-mdc-form-field-icon-suffix {
          position: absolute;
          right: 0;
          padding-right: 16px;

          &:has(.mat-datepicker-toggle),
          &:has(.mdc-icon-button) {
            padding-right: 8px;
          }
        }

        .mat-mdc-form-field-icon-suffix,
        .mat-mdc-form-field-icon-prefix {
          span {
            color: $primary-color-dark-blue;
            font-size: 16px;
            font-weight: $font-weight-semibold;
          }

          .mdc-icon-button {
            width: 24px !important;
            height: 24px !important;
          }

          .mat-datepicker-toggle {
            width: 24px !important;
            height: 24px !important;

            .mdc-icon-button {
              width: 100% !important;
              height: 100% !important;
            }

            .mat-mdc-button-persistent-ripple,
            .mat-mdc-focus-indicator,
            .mat-ripple {
              display: none;
            }
          }
        }

        &:has(vdw-bit-pattern-editor-form-field) {
          height: 56px;
        }

        &:has(.mat-mdc-form-field-icon-prefix) .mat-mdc-form-field-infix {
          padding-left: 40px;
        }

        &:has(.mat-mdc-form-field-icon-suffix) .mat-mdc-form-field-infix {
          padding-right: 40px;
        }
      }

      &:has(.mat-mdc-form-field-textarea-control) {
        height: unset;

        .mat-mdc-form-field-flex {
          height: unset;

          .mat-mdc-form-field-infix {
            height: 112px;
          }
        }
      }

      &.mdc-text-field--invalid .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          background-color: $tint-red-6;
          border-color: $tint-red-70 !important;
        }

        .mat-mdc-input-element {
          color: $functional-color-red;
          caret-color: $functional-color-red;
        }

        mat-label,
        .mat-mdc-form-field-icon-suffix span {
          color: $functional-color-red;
        }
      }

      &.mdc-text-field--disabled {
        pointer-events: unset !important;
      }

      &.mdc-text-field--disabled .mat-mdc-form-field-flex,
      &:has(.mat-mdc-input-element:disabled) .mat-mdc-form-field-flex,
      &:has(.mat-mdc-select-disabled) {
        .mat-mdc-form-field-infix {
          background-color: $tint-darkblue-6;
          border-width: 0;
        }

        mat-label {
          color: $tint-darkblue-70;
        }

        .mat-mdc-input-element,
        .mat-mdc-form-field-icon-suffix span {
          color: $tint-darkblue-50;
        }
      }

      &.mdc-text-field--no-label .mat-mdc-form-field-flex {
        margin-top: 0;
      }
    }

    .mat-mdc-form-field-subscript-wrapper {
      .mat-mdc-form-field-error-wrapper,
      .mat-mdc-form-field-hint-wrapper {
        width: 100%;
        padding: 0;
      }

      .mat-mdc-form-field-error,
      .mat-mdc-form-field-hint {
        width: 100%;
        text-align: end;
        font-size: 13px;
        font-weight: $font-weight-regular;
      }

      .mat-select-value {
        color: $tint-darkblue-50;
      }

      .mat-mdc-form-field-error {
        color: $functional-color-red;
      }

      .mat-mdc-form-field-hint {
        color: $tint-darkblue-50;

        .url {
          margin-left: 4px;
        }
      }

      .mat-mdc-form-field-hint-spacer {
        display: none;
      }
    }

    &.mat-form-field-type-mat-chip-list {
      .mat-form-field-suffix {
        padding-right: 10px;
      }

      .mat-form-field-infix {
        padding-right: 0 !important;
      }
    }

    &:focus-within,
    &:hover {
      .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
        border-color: $tint-vdw-blue-70;
      }
    }

    &.no-subscript .mat-mdc-form-field-subscript-wrapper::before {
      display: none;
    }

    &:has(vdw-bit-pattern-editor-form-field) {
      width: 420px;
    }
  }

  .mat-mdc-form-field-type-mat-select {
    .mat-mdc-text-field-wrapper {
      padding: 0;

      .mat-mdc-form-field-flex {
        height: 44px;

        .mat-mdc-form-field-infix {
          .mat-mdc-select {
            display: flex;
            height: 100%;
            width: 100%;
            padding: 0 8px 0 16px;

            .mat-mdc-select-trigger {
              .mat-mdc-select-value {
                color: $primary-color-dark-blue;
              }

              .mat-mdc-select-arrow-wrapper {
                width: 24px;
                height: 24px;
                transform: none;

                .mat-mdc-select-arrow {
                  width: 100%;
                  height: 100%;
                  background-color: $primary-color-dark-blue;
                  -webkit-mask-image: url('../../assets/icons/24px/chevron-down-error.svg');
                  -moz-mask-image: url('../../assets/icons/24px/chevron-down-error.svg');
                  mask-image: url('../../assets/icons/24px/chevron-down-error.svg');

                  svg {
                    display: none;
                  }
                }
              }
            }

            &.mat-mdc-select-invalid,
            &:invalid {
              .mat-mdc-select-value {
                color: $functional-color-red;
              }

              .mat-mdc-select-arrow {
                background-color: $functional-color-red !important;
              }
            }

            &.mat-mdc-select-disabled,
            &:disabled {
              .mat-mdc-select-value {
                color: $tint-darkblue-50;
              }

              .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
                background-color: $tint-darkblue-50;
              }
            }
          }
        }
      }

      &.mdc-text-field--no-label {
        max-height: 44px;
      }
    }
  }

  .mat-mdc-select-panel,
  .mat-mdc-autocomplete-panel {
    margin-top: 4px !important;
    padding: 0 !important;
    border: 1px solid $tint-darkblue-20 !important;
    border-radius: 3px !important;
    box-shadow: none !important;

    .mat-mdc-option {
      min-height: 0;
      padding: 10px 16px;
      border-bottom: 1px solid $tint-darkblue-20;

      &:last-of-type {
        border-bottom: none;
      }

      .mdc-list-item__primary-text {
        font-size: 14px;
        color: $primary-color-dark-blue !important;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .mat-mdc-option-pseudo-checkbox {
        display: none;
      }

      &:hover:not(.mdc-list-item--disabled):not(.mdc-list-item--selected):not(.mat-mdc-option-active) {
        background-color: $tint-lightblue-10 !important;
      }

      &.mdc-list-item--selected,
      &.mat-mdc-option-active {
        background-color: $tint-lightblue-30 !important;
      }
    }
  }

  .mat-mdc-option-multiple {
    .mat-mdc-option-pseudo-checkbox {
      display: block !important;
      border: 1px solid $tint-darkblue-20;

      &:hover {
        border: 1px solid $primary-color-dark-blue;
      }
    }
  }

  .input-link {
    background: $tint-darkblue-2;
    border: 1px solid $tint-darkblue-20;
    border-radius: 3px;
    cursor: pointer;
    display: block;
    height: 40px;
    line-height: 40px;
    margin-top: 3px;
    padding: 0 16px;
    text-decoration: underline;

    a {
      color: $primary-color-vdw-blue;
    }
  }

  button {
    &.invalid:not(:disabled) {
      background: $tint-red-6 !important;
      border: 1px solid $functional-color-red !important;
      color: $functional-color-red !important;
      box-shadow: unset !important;

      &:hover {
        background: $tint-red-10 !important;
      }

      &.cdk-mouse-focused {
        background: $tint-red-20 !important;
      }
    }
  }

  .mat-mdc-form-field-hint {
    color: $tint-darkblue-50;
  }

  .mat-mdc-form-field-error {
    color: $functional-color-red !important;

    &.button-validation {
      font-weight: 400;
      line-height: 1.125;
      margin-top: 5px;
    }

    &.form-validation-error {
      color: $functional-color-red;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      text-align: right;
      width: initial;
    }
  }

  .mat-mdc-slide-toggle {
    .mdc-switch {
      --mdc-switch-track-width: 26px;
      --mdc-switch-track-height: 16px;
      --mdc-switch-track-shape: 8px;
      --mat-switch-with-icon-handle-size: 8px;
      --mat-switch-pressed-handle-size: 8px;
      --mdc-switch-handle-shape: 6px;
      --mdc-switch-disabled-track-opacity: 1;
      --mdc-switch-disabled-handle-opacity: 1;
      --mdc-switch-unselected-track-color: transparent;
      --mdc-switch-unselected-focus-track-color: #{$neutral-950};
      --mdc-switch-unselected-pressed-track-color: #{$neutral-950};
      --mdc-switch-unselected-hover-track-color: #{$neutral-950};
      --mdc-switch-selected-track-color: #{$primary-400};
      --mdc-switch-selected-focus-track-color: #{$primary-500};
      --mdc-switch-selected-pressed-track-color: #{$primary-500};
      --mdc-switch-selected-hover-track-color: #{$primary-500};
      --mdc-switch-unselected-handle-color: #{$neutral-400};
      --mdc-switch-unselected-focus-handle-color: #{$primary-400};
      --mdc-switch-unselected-pressed-handle-color: #{$primary-400};
      --mdc-switch-unselected-hover-handle-color: #{$primary-400};
      --mdc-switch-selected-handle-color: #{$neutral-white};
      --mdc-switch-selected-focus-handle-color: #{$neutral-white};
      --mdc-switch-selected-pressed-handle-color: #{$neutral-white};
      --mdc-switch-selected-hover-handle-color: #{$neutral-white};
      --mdc-switch-disabled-unselected-track-color: #{$neutral-950};
      --mdc-switch-disabled-selected-track-color: #{$neutral-800};
      --mdc-switch-disabled-unselected-handle-color: #{$neutral-800};
      --mdc-switch-disabled-selected-handle-color: #{$neutral-white};

      display: flex !important;
    }

    &.error .mdc-switch {
      --mdc-switch-unselected-focus-track-color: transparent;
      --mdc-switch-unselected-pressed-track-color: transparent;
      --mdc-switch-unselected-hover-track-color: transparent;
      --mdc-switch-selected-track-color: #{$semantic-error-400};
      --mdc-switch-selected-focus-track-color: #{$semantic-error-400};
      --mdc-switch-selected-pressed-track-color: #{$semantic-error-400};
      --mdc-switch-selected-hover-track-color: #{$semantic-error-400};
      --mdc-switch-unselected-handle-color: #{$semantic-error-400};
      --mdc-switch-unselected-focus-handle-color: #{$semantic-error-400};
      --mdc-switch-unselected-pressed-handle-color: #{$semantic-error-400};
      --mdc-switch-unselected-hover-handle-color: #{$semantic-error-400};

      &:not(.mdc-switch--disabled) + label {
        color: $semantic-error-400;
      }
    }

    .mdc-form-field {
      .mdc-switch {
        border-radius: var(--mdc-switch-track-shape);

        .mdc-switch__track {
          border: 1px solid var(--mdc-switch-unselected-handle-color);
        }

        .mdc-switch__handle-track .mdc-switch__handle {
          .mdc-switch__shadow {
            box-shadow: none;
          }

          .mdc-switch__ripple {
            display: none;
          }

          .mdc-switch__icons {
            display: none;
          }
        }

        &.mdc-switch--selected {
          background-color: var(--mdc-switch-selected-track-color);

          .mdc-switch__track {
            border-color: var(--mdc-switch-selected-track-color);

            &::before {
              margin-left: 1px;
            }
          }

          .mdc-switch__handle-track {
            --mat-switch-with-icon-handle-size: 12px;
            --mat-switch-pressed-handle-size: 12px;
            --mdc-switch-handle-width: 12px;

            .mdc-switch__handle {
              margin-left: -2px;
            }
          }

          &:enabled:hover {
            background-color: var(--mdc-switch-selected-hover-track-color);

            .mdc-switch__track {
              border-color: var(--mdc-switch-selected-hover-track-color);
            }
          }

          &:enabled:focus {
            background-color: var(--mdc-switch-selected-focus-track-color);

            .mdc-switch__track {
              border-color: var(--mdc-switch-selected-focus-track-color);
            }
          }

          &:disabled {
            background-color: var(--mdc-switch-disabled-selected-track-color);
          }
        }

        &.mdc-switch--unselected {
          .mdc-switch__track::after {
            margin-left: -1px;
          }

          .mdc-switch__handle-track .mdc-switch__handle {
            margin-left: 4px !important;
          }

          &:enabled:hover {
            background-color: var(--mdc-switch-unselected-hover-track-color);

            .mdc-switch__track {
              border-color: var(--mdc-switch-unselected-hover-handle-color);
            }
          }

          &:enabled:focus {
            background-color: var(--mdc-switch-unselected-focus-track-color);

            .mdc-switch__track {
              border-color: var(--mdc-switch-unselected-focus-handle-color);
            }
          }

          &:disabled {
            background-color: var(--mdc-switch-disabled-unselected-track-color);
          }
        }

        &:disabled .mdc-switch__track {
          border-color: var(--mdc-switch-disabled-selected-track-color);
        }
      }

      label {
        padding-left: 8px;
        font-size: 14px;
        line-height: 16px;
        color: $neutral-black;
        font-weight: $font-weight-regular;
        white-space: nowrap;
        -webkit-font-smoothing: antialiased;

        &:empty {
          display: none;
        }
      }
    }

    .mdc-switch--disabled + label {
      color: $neutral-600;
    }

    & * {
      transition-duration: 150ms !important;

      &::after,
      &::before {
        transition-duration: 150ms !important;
      }
    }
  }

  .mat-mdc-radio-button {
    display: flex;
    align-items: flex-start;

    .mdc-form-field {
      .mdc-radio {
        --mdc-radio-unselected-icon-color: #{$neutral-400};
        --mdc-radio-unselected-focus-icon-color: #{$primary-400};
        --mdc-radio-unselected-hover-icon-color: #{$primary-400};
        --mdc-radio-unselected-pressed-icon-color: #{$primary-400};
        --mdc-radio-selected-icon-color: #{$primary-400};
        --mdc-radio-selected-focus-icon-color: #{$primary-500};
        --mdc-radio-selected-hover-icon-color: #{$primary-500};
        --mdc-radio-selected-pressed-icon-color: #{$primary-500};
        --mdc-radio-disabled-unselected-icon-color: #{$neutral-800};
        --mdc-radio-disabled-selected-icon-color: #{$neutral-700};
        --mdc-radio-disabled-unselected-icon-opacity: 1;
        --mdc-radio-disabled-selected-icon-opacity: 1;

        min-width: 16px;
        max-width: 16px;
        min-height: 16px;
        max-height: 16px;
        padding: 0;

        .mat-mdc-radio-touch-target,
        .mdc-radio__native-control,
        .mdc-radio__background {
          width: 100%;
          height: 100%;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          transform: none;
        }

        .mat-radio-ripple,
        .mdc-radio__background::before {
          display: none;
        }

        .mdc-radio__background {
          .mdc-radio__outer-circle {
            border-width: 1px;
          }

          .mdc-radio__inner-circle {
            transform: translate(-2px, -2px) scale(0);
            border-color: $neutral-white !important;
            transition:
              transform 280ms 0ms cubic-bezier(0, 0, 0.2, 1),
              border-color 0ms 0ms cubic-bezier(0, 0, 0.2, 1);
          }
        }

        .mdc-radio__native-control:checked {
          &:enabled + .mdc-radio__background .mdc-radio__outer-circle {
            background-color: var(--mdc-radio-selected-icon-color);
          }

          & + .mdc-radio__background .mdc-radio__inner-circle {
            transform: translate(-2px, -2px) scale(0.3);
          }
        }

        .mdc-radio__native-control:disabled {
          & + .mdc-radio__background .mdc-radio__outer-circle {
            background-color: $neutral-950;
          }

          & + .mdc-radio__background .mdc-radio__inner-circle {
            border-color: $neutral-950 !important;
          }

          &:checked + .mdc-radio__background .mdc-radio__outer-circle {
            background-color: var(--mdc-radio-disabled-selected-icon-color);
          }
        }

        &:hover .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
          background-color: var(--mdc-radio-selected-hover-icon-color);
        }
      }

      .mdc-radio--disabled + label {
        color: $neutral-600;
      }

      label {
        color: $neutral-black;
        padding-left: 8px;
        white-space: nowrap;
        line-height: 16px;
      }
    }
  }

  .mat-mdc-radio-group.ng-invalid.ng-pristine.ng-touched .mat-mdc-radio-button .mdc-form-field .mdc-radio {
    --mdc-radio-unselected-icon-color: #{$semantic-error-400};
    --mdc-radio-unselected-focus-icon-color: #{$semantic-error-400};
    --mdc-radio-unselected-hover-icon-color: #{$semantic-error-400};
    --mdc-radio-unselected-pressed-icon-color: #{$semantic-error-400};
    --mdc-radio-selected-icon-color: #{$semantic-error-400};
    --mdc-radio-selected-focus-icon-color: #{$semantic-error-400};
    --mdc-radio-selected-hover-icon-color: #{$semantic-error-400};
    --mdc-radio-selected-pressed-icon-color: #{$semantic-error-400};

    &:not(.mdc-radio--disabled) + label {
      color: $semantic-error-400;
    }
  }

  .mat-mdc-radio-group.radio-toggle-buttons {
    .mat-mdc-radio-button {
      position: relative;
      display: flex;
      align-items: center;
      height: 44px;
      padding: 0 16px;
      background-color: $tint-darkblue-2;
      border-color: $tint-darkblue-20;
      border-style: solid;
      border-width: 1px 0 1px 1px;
      font-size: 14px;

      .mdc-form-field {
        .mdc-radio .mat-mdc-radio-touch-target {
          position: absolute;
          height: 44px;
          top: -14px;
          left: -16px;
          width: calc(3 * 100%);
        }

        label {
          padding: 12px 0 12px 8px;
        }
      }

      &.mat-mdc-radio-checked {
        background-color: $tint-lightblue-30;
      }

      &:first-of-type {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      &:last-of-type {
        border-right-width: 1px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }

      &:hover {
        &:not(:has(.mdc-radio--disabled)) {
          cursor: pointer;

          .mdc-form-field label {
            cursor: pointer;
          }
        }

        &:not(.mat-mdc-radio-checked):not(:has(.mdc-radio--disabled)) {
          background-color: $tint-lightblue-10-transparent;
        }
      }
    }

    padding-bottom: 22px;
  }

  .mat-mdc-checkbox {
    display: flex !important;

    .mdc-form-field {
      width: 100%;

      .mdc-checkbox {
        --mdc-checkbox-unselected-icon-color: #{$neutral-400};
        --mdc-checkbox-unselected-focus-icon-color: #{$primary-400};
        --mdc-checkbox-unselected-hover-icon-color: #{$primary-400};
        --mdc-checkbox-unselected-pressed-icon-color: #{$primary-400};
        --mdc-checkbox-selected-icon-color: #{$primary-400};
        --mdc-checkbox-selected-focus-icon-color: #{$primary-500};
        --mdc-checkbox-selected-hover-icon-color: #{$primary-500};
        --mdc-checkbox-selected-pressed-icon-color: #{$primary-500};
        --mdc-checkbox-disabled-unselected-icon-color: #{$neutral-800};
        --mdc-checkbox-disabled-selected-icon-color: #{$neutral-700};

        min-width: 16px;
        max-width: 16px;
        min-height: 16px;
        max-height: 16px;
        padding: 0;

        .mat-mdc-checkbox-touch-target,
        .mdc-checkbox__native-control,
        .mdc-checkbox__background {
          width: 100%;
          height: 100%;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          transform: none;
        }

        .mdc-checkbox__ripple {
          display: none;
        }

        .mdc-checkbox__background {
          border-width: 1px;

          .mdc-checkbox__checkmark {
            scale: 1.14;

            .mdc-checkbox__checkmark-path {
              stroke-width: 0;
              fill: $neutral-white;
              scale: 1.5;
              d: path(
                'M 6.66654 9.06668 L 10.5999 5.13335 C 10.7221 5.01112 10.8776 4.95001 11.0665 4.95001 C 11.2554 4.95001 11.411 5.01112 11.5332 5.13335 C 11.6554 5.25557 11.7165 5.41112 11.7165 5.60001 C 11.7165 5.7889 11.6554 5.94446 11.5332 6.06668 L 7.1332 10.4667 C 6.99987 10.6 6.84431 10.6667 6.66654 10.6667 C 6.48876 10.6667 6.3332 10.6 6.19987 10.4667 L 4.46654 8.73335 C 4.34431 8.61112 4.2832 8.45557 4.2832 8.26668 C 4.2832 8.07779 4.34431 7.92223 4.46654 7.80001 C 4.58876 7.67779 4.74431 7.61668 4.9332 7.61668 C 5.12209 7.61668 5.27765 7.67779 5.39987 7.80001 L 6.66654 9.06668 Z'
              );
            }
          }
        }

        .mdc-checkbox__mixedmark {
          --mdc-checkbox-selected-checkmark-color: #{$primary-400};
          --mdc-checkbox-disabled-selected-checkmark-color: #{$neutral-700};

          width: 10px;
          border-radius: 1px;
          scale: 0.67;
        }
      }

      label {
        width: 100%;
        overflow: hidden;
        text-align: left;
        font-size: 14px;
        color: $neutral-black;
        padding-left: 8px;
        line-height: 16px;
      }
    }

    &:hover .mdc-form-field .mdc-checkbox .mdc-checkbox__mixedmark {
      --mdc-checkbox-selected-checkmark-color: #{$primary-500};
    }

    &.invalid,
    &.ng-invalid.ng-pristine.ng-touched {
      .mdc-form-field .mdc-checkbox {
        --mdc-checkbox-unselected-icon-color: #{$semantic-error-400};
        --mdc-checkbox-unselected-focus-icon-color: #{$semantic-error-400};
        --mdc-checkbox-unselected-hover-icon-color: #{$semantic-error-400};
        --mdc-checkbox-unselected-pressed-icon-color: #{$semantic-error-400};
        --mdc-checkbox-selected-icon-color: #{$semantic-error-400};
        --mdc-checkbox-selected-focus-icon-color: #{$semantic-error-400};
        --mdc-checkbox-selected-hover-icon-color: #{$semantic-error-400};
        --mdc-checkbox-selected-pressed-icon-color: #{$semantic-error-400};

        .mdc-checkbox__mixedmark {
          --mdc-checkbox-selected-checkmark-color: #{$semantic-error-400};
        }
      }

      label {
        color: $semantic-error-400;
      }
    }

    &.mdc-checkbox--disabled {
      &:not(.mat-mdc-checkbox-checked) .mdc-checkbox__background {
        background-color: $neutral-950 !important;
      }

      .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background {
        border-color: var(--mdc-checkbox-disabled-selected-icon-color) !important;
        background-color: $neutral-950 !important;
      }

      label {
        color: $neutral-600;
      }
    }

    .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background {
      background-color: transparent !important;
    }
  }

  .checkbox-toggle-buttons {
    display: flex;

    .mat-mdc-checkbox {
      display: flex;
      background-color: $primary-color-white;
      border-color: $tint-darkblue-20;
      border-style: solid;
      border-width: 1px 0 1px 1px;
      height: 44px;

      &.mat-mdc-checkbox-checked {
        background-color: $tint-lightblue-30;
      }

      &:first-of-type {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      &:last-of-type {
        border-right-width: 1px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }

      .mdc-form-field {
        height: 100%;
        padding-left: 16px;

        .mat-mdc-checkbox-touch-target {
          width: 32px;
          height: 40px;
          top: -12px;
          left: -16px;
        }

        label {
          padding: 10px 16px 10px 8px;
        }
      }
    }
  }

  fieldset {
    border: none;
    padding: 0;
    margin: 0;
  }

  .custom-form-field > .mat-mdc-text-field-wrapper > .mat-mdc-form-field-flex > .mat-mdc-form-field-infix,
  .custom-form-field.mat-form-field-invalid > .mat-mdc-text-field-wrapper > .mat-mdc-form-field-flex > .mat-mdc-form-field-infix,
  .custom-form-field.mat-form-field-invalid.mat-form-field-appearance-fill > .mat-mdc-text-field-wrapper > .mat-mdc-form-field-flex > .mat-mdc-form-field-infix,
  .custom-form-field.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float.mat-form-field-invalid
    > .mat-mdc-text-field-wrapper
    > .mat-mdc-form-field-flex
    > .mat-mdc-form-field-infix {
    border: none !important;
    background-color: unset !important;
    padding-left: 0 !important;
  }

  .mat-form-field-readonly {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          background: $tint-darkblue-6 !important;
          border-color: $tint-darkblue-6 !important;
        }
      }
    }

    .mat-mdc-input-element:read-only {
      color: $tint-darkblue-50-transparent !important;
      cursor: default;
    }

    .mat-mdc-form-field-icon-suffix span {
      color: $tint-darkblue-50-transparent !important;
    }
  }

  .form-field-skeleton-wrapper {
    width: 100%;
    min-width: 0;
  }

  .blurry-content-fix {
    mat-form-field mat-label,
    .mat-mdc-form-field-infix mat-label,
    mat-datepicker-toggle button,
    vdw-datepicker-form-input .mdc-text-field__input,
    .mdc-text-field__input {
      will-change: unset !important;
      -webkit-filter: blur(0) !important;
      backface-visibility: hidden !important;
      transform: translateZ(0) !important;
      -webkit-font-smoothing: subpixel-antialiased !important;
    }
  }

  .mat-mdc-form-field-error-wrapper mat-error:not(:first-child) {
    display: none;
  }

  .bms-theme {
    .mat-mdc-form-field .mat-mdc-form-field-flex .mat-mdc-floating-label mat-label,
    .mock-mat-form-field mat-label {
      display: inline-block;
      width: unset;
      font-size: 12px;
      line-height: 12px;
      font-weight: $font-weight-regular;
      color: $neutral-black;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .mock-mat-form-field mat-label {
      -webkit-font-smoothing: antialiased;
      height: 20px;
      line-height: 20px;
    }

    .mat-mdc-form-field {
      .mat-mdc-text-field-wrapper {
        background-color: $neutral-white;
        padding: 0;
        border-radius: 0;

        .mat-mdc-form-field-focus-overlay,
        .mdc-line-ripple {
          display: none;
        }

        .mat-mdc-form-field-flex {
          height: 32px;
          margin-top: 20px;
          padding: 8px;
          border-radius: 4px;
          background-color: $neutral-white;
          border: 1px solid $neutral-800;
          gap: 4px;
          align-items: center;

          .mat-mdc-floating-label {
            top: -28px;
            left: -8px;
            transform: none;

            .mat-mdc-form-field-required-marker {
              display: inline-block;
              color: $semantic-error-400;
            }
          }

          &:not(:has(.mat-mdc-floating-label)) {
            background-color: $neutral-950 !important;

            &:focus-within {
              background-color: $neutral-white !important;
            }
          }

          .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
            margin-left: 2px;
          }

          .mat-mdc-form-field-infix {
            display: flex;
            min-height: 16px;
            border-radius: 4px;
            background-color: unset;
            border: none;
            padding: 0;
            gap: 4px;

            .mat-mdc-input-element {
              height: 100%;
              width: 100%;
              padding: 0;
              caret-color: $neutral-black;
              color: $neutral-black;
              font-size: 14px;
              line-height: 16px;

              &::placeholder {
                color: $neutral-700;
              }

              &[type='number'] {
                -moz-appearance: textfield;
              }

              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
              }

              &.url {
                color: $neutral-400 !important;
              }
            }

            .clear-input-button {
              font-size: 16px;
              background: $neutral-900;
              border-radius: 8px;

              --mdc-icon-button-state-layer-size: 16px;

              svg,
              .mat-mdc-button-touch-target {
                min-width: 12px;
                width: 12px !important;
                height: 12px !important;
                font-size: 12px !important;
              }

              .mat-icon {
                display: inline-block;
                vertical-align: unset;
              }
            }
          }

          .mat-mdc-form-field-icon-prefix {
            position: absolute;
            left: 0;
            padding-left: 16px;
            z-index: 1;

            .mdc-icon-button {
              background: transparent !important;
            }

            &:has(.mat-datepicker-toggle),
            &:has(.mdc-icon-button) {
              padding-left: 8px;
            }
          }

          .mat-mdc-form-field-icon-suffix {
            position: unset;
            right: 0;
            padding: 0;
          }

          .mat-mdc-form-field-icon-suffix,
          .mat-mdc-form-field-icon-prefix {
            display: inline-flex;
            font-weight: $font-weight-semibold;
            font-size: 14px;
            line-height: 16px;
            color: $neutral-black;
            gap: 4px;

            span {
              display: inline-flex;
              font-weight: $font-weight-semibold;
              font-size: 14px;
              line-height: 16px;
              color: $neutral-black;
              gap: 4px;
            }

            .mat-icon {
              width: 16px !important;
              height: 16px !important;
              padding: 0;
            }

            .mdc-icon-button {
              width: 16px !important;
              height: 16px !important;
            }

            .mat-datepicker-toggle {
              width: 16px !important;
              height: 16px !important;

              .mdc-icon-button {
                width: 100% !important;
                height: 100% !important;
              }

              .mat-mdc-button-persistent-ripple,
              .mat-mdc-focus-indicator,
              .mat-ripple {
                display: none;
              }
            }
          }

          &:has(vdw-bit-pattern-editor-form-field) {
            height: 56px;
          }

          .arrow-wrapper .mat-mdc-icon-button {
            display: block !important;
            font-size: 20px;
            border-radius: 2px;
            border: unset;
            background-color: unset;

            --mdc-icon-button-state-layer-size: 20px;

            .mat-mdc-button-touch-target {
              min-width: 16px;
              width: 16px !important;
              height: 16px !important;
              font-size: 16px !important;
            }

            .mat-icon {
              height: 16px;
              width: 16px;
              mask-size: 16px, 16px;
              background-color: $neutral-600;
              display: inline-block;
              vertical-align: unset;
            }

            &.cdk-mouse-focused,
            &:hover {
              .mat-icon {
                background-color: $neutral-black;
              }
            }

            &:hover {
              background-color: $neutral-900 !important;

              &.cdk-mouse-focused {
                background-color: $neutral-850 !important;
              }
            }
          }
        }

        &:has(.mat-mdc-form-field-textarea-control) {
          height: unset;

          .mat-mdc-form-field-flex {
            height: unset;
            padding: 0;
            border-radius: 0;
            border: none;

            .mat-mdc-floating-label {
              top: -20px;
              left: 0;
            }

            .mat-mdc-form-field-infix {
              height: 100%;

              .mat-mdc-input-element {
                resize: vertical;
                padding: 8px;
                border-radius: 4px;
                background-color: $neutral-white;
                border: 1px solid $neutral-800;

                &::-webkit-resizer {
                  content: '';
                }
              }

              &::after {
                position: absolute;
                width: 7px;
                height: 7px;
                background-color: $neutral-700;
                mask-image: url('../../assets/solid-icons/solid-textarea.svg');
                mask-size: 100%;
                right: 0;
                bottom: 0.5px;
                content: '';
                cursor: n-resize;
              }

              &:has(.ng-invalid) {
                &::after {
                  background-color: $neutral-600;
                }
              }

              &:has([disabled]) {
                &::after {
                  background-color: $neutral-800;
                }
              }

              .clear-input-button {
                position: absolute;
                right: 8px;
                bottom: 8px;
              }
            }
          }
        }

        &.mdc-text-field--invalid .mat-mdc-form-field-flex,
        &:has(.mat-mdc-form-field-textarea-control).mdc-text-field--invalid .mat-mdc-form-field-flex .mat-mdc-input-element {
          border-color: $semantic-error-400;
        }

        &.mdc-text-field--disabled {
          pointer-events: unset !important;

          .arrow-wrapper .mat-mdc-icon-button {
            &.cdk-mouse-focused,
            &:hover {
              .mat-icon {
                background-color: $neutral-600;
              }
            }

            &:hover {
              background-color: $neutral-900 !important;

              &.cdk-mouse-focused {
                background-color: $neutral-900 !important;
              }
            }
          }
        }

        &.mdc-text-field--disabled .mat-mdc-form-field-flex,
        &:has(.mat-mdc-input-element:disabled) .mat-mdc-form-field-flex,
        &:has(.mat-mdc-select-disabled) .mat-mdc-form-field-flex,
        &:has(.mat-mdc-form-field-textarea-control).mdc-text-field--disabled .mat-mdc-form-field-flex .mat-mdc-input-element {
          background-color: $neutral-900;
          border-color: $neutral-850 !important;
          border-radius: 4px;

          .mat-mdc-form-field-infix {
            background-color: unset;
          }

          .mat-mdc-input-element,
          .mat-mdc-form-field-icon-suffix span {
            color: $neutral-600;
          }

          .mat-icon svg * {
            fill: $neutral-600;
          }
        }

        &.mdc-text-field--no-label .mat-mdc-form-field-flex {
          margin-top: 0;
        }
      }

      .mat-mdc-form-field-subscript-wrapper {
        .mat-mdc-form-field-error-wrapper,
        .mat-mdc-form-field-hint-wrapper {
          width: 100%;
          padding: 0;
        }

        .mat-mdc-form-field-error,
        .mat-mdc-form-field-hint {
          width: 100%;
          font-size: 12px;
          line-height: 12px;
          font-weight: $font-weight-regular;
          padding: 4px;

          &::before {
            content: none;
          }
        }

        &::before {
          height: 0;
        }

        .mat-mdc-form-field-error {
          color: $semantic-error-400 !important;
          text-align: unset;

          > * {
            display: inline-block;
          }

          .url {
            color: $semantic-error-400 !important;
          }
        }

        .mat-mdc-form-field-hint {
          color: $neutral-400;
          text-align: end;

          .url {
            margin-left: 2px;
            color: $neutral-400 !important;
          }
        }

        .mat-mdc-form-field-hint-spacer {
          display: none;
        }
      }

      &.mat-form-field-type-mat-chip-list {
        .mat-form-field-suffix {
          padding-right: 10px;
        }

        .mat-form-field-infix {
          padding-right: 0 !important;
        }
      }

      &:focus-within,
      &:hover,
      &:active {
        .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex,
        .mat-mdc-text-field-wrapper:has(.mat-mdc-form-field-textarea-control) .mat-mdc-form-field-flex .mat-mdc-input-element {
          border-color: $primary-400;
        }

        .mdc-text-field--invalid .mat-mdc-form-field-flex,
        .mdc-text-field--invalid:has(.mat-mdc-form-field-textarea-control) .mat-mdc-form-field-flex .mat-mdc-input-element {
          border-color: $semantic-error-400;
        }
      }

      &.no-subscript .mat-mdc-form-field-subscript-wrapper::before {
        display: none;
      }

      &:has(vdw-bit-pattern-editor-form-field) {
        width: 420px;
      }
    }

    &.mat-form-field-type-mat-select,
    &.mat-mdc-form-field-type-mat-select,
    &:has(.mat-mdc-select) {
      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
          .mat-mdc-select {
            padding: 0;
            line-height: 16px;
            font-size: 14px;

            .mat-mdc-select-trigger {
              gap: 4px;

              .mat-mdc-select-value {
                color: $neutral-black;
              }

              .mat-mdc-select-value:has(.mat-mdc-select-placeholder) {
                color: $neutral-700;
              }

              .mat-mdc-select-placeholder {
                color: $neutral-700;
              }

              .mat-mdc-select-arrow-wrapper {
                width: 16px;
                height: 16px;
                transform: none;

                .mat-mdc-select-arrow {
                  width: 100%;
                  height: 100%;
                  background-color: $neutral-black;
                  -webkit-mask-image: url('../../assets/solid-icons/solid-keyboard-arrow-down.svg');
                  -moz-mask-image: url('../../assets/solid-icons/solid-keyboard-arrow-down.svg');
                  mask-image: url('../../assets/solid-icons/solid-keyboard-arrow-down.svg');
                  mask-size: 16px, 16px;

                  svg {
                    display: none;
                  }
                }
              }
            }

            &.mat-mdc-select-invalid,
            &:invalid {
              .mat-mdc-select-arrow {
                background-color: $neutral-black !important;
              }
            }

            &.mat-mdc-select-disabled,
            &:disabled {
              .mat-mdc-select-value {
                color: $neutral-600;
              }

              .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
                background-color: $neutral-600;
              }
            }
          }

          &:has(vdw-object-selection) .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper {
            display: none;
          }
        }

        &.mdc-text-field--no-label {
          max-height: 32px;
        }
      }
    }

    &.mat-mdc-select-multiple.mat-mdc-select-disabled .mat-mdc-select-trigger {
      cursor: pointer !important;
    }

    &.disabled-dropdown.mat-mdc-menu-panel,
    &.mat-mdc-select-panel,
    &.mat-mdc-autocomplete-panel {
      margin-top: 0 !important;
      padding: 8px !important;
      border: none !important;
      border-radius: 4px !important;
      box-shadow:
        0 0 3px 0 $tint-neutral-black-20-transparent,
        0 8px 16px -4px $tint-neutral-black-15-transparent !important;

      .options-content {
        height: calc(100% - 40px);
      }

      .no-results-found {
        margin: 0;
        line-height: 16px;
        font-size: 14px;
        color: $neutral-400;
      }

      .mat-mdc-option {
        min-height: 32px;
        border-radius: 4px;
        padding: 6px 8px;
        gap: 8px;
        border-bottom: none;

        .mdc-list-item__primary-text {
          font-size: 14px;
          line-height: 16px;
          color: $neutral-black !important;
          white-space: nowrap;
          width: 100%;

          .color-preview {
            width: 16px;
            height: 16px;
            min-width: 16px;
            min-height: 16px;
            border-radius: 2px;
          }
        }

        .mat-mdc-icon-button:has(.mat-icon[svgicon='solid-open-in-full']) {
          display: none;
        }

        .mat-mdc-option-pseudo-checkbox {
          display: none;
        }

        &:hover:not(.mdc-list-item--disabled) {
          background-color: $primary-900 !important;

          .mdc-list-item__primary-text {
            color: $primary-400 !important;

            :has(.color-preview) vdw-ellipsis-label {
              width: calc(100% - 28px - 24px);
            }
          }

          vdw-ellipsis-label {
            width: calc(100% - 28px);
          }

          .mat-mdc-icon-button:has(.mat-icon[svgicon='solid-open-in-full']) {
            width: 20px;
            height: 20px;
            display: block;
            border-radius: 2px !important;
            padding: 2px !important;

            .mat-mdc-button-touch-target {
              min-width: 20px;
              width: 20px !important;
              height: 20px !important;
            }

            svg {
              min-width: 16px;
              width: 16px !important;
              height: 16px !important;

              * {
                fill: $neutral-600;
              }
            }

            .mat-icon {
              margin-right: unset;
            }

            &:hover {
              background-color: $primary-800 !important;
            }

            &:focus {
              background-color: $primary-700 !important;
            }

            &:hover,
            &:focus {
              svg * {
                fill: $primary-400;
              }
            }
          }
        }

        &.mat-mdc-option-multiple,
        &:not(.mdc-list-item--selected) {
          background-color: unset !important;
        }
      }

      .search-input {
        margin-bottom: 8px;

        .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
          background-image: url('../../assets/solid-icons/solid-search.svg');
          background-position: 8px 8px;
          background-repeat: no-repeat;
          background-size: 16px;
          padding: 8px 8px 8px 28px;
        }

        .mat-mdc-form-field-subscript-wrapper {
          display: none;
        }
      }

      .mat-mdc-checkbox {
        padding: 6px 8px;
      }

      &.select-panel-invalid .mat-mdc-option {
        &.mdc-list-item--selected {
          background-color: $semantic-error-900 !important;

          .mdc-list-item__primary-text {
            color: $semantic-error-400 !important;
          }

          &:hover .mat-mdc-icon-button:has(.mat-icon[svgicon='solid-open-in-full']) {
            &:hover {
              background-color: $semantic-error-800 !important;
            }

            &:focus {
              background-color: $semantic-error-700 !important;
            }

            &:hover,
            &:focus {
              svg * {
                fill: $semantic-error-400;
              }
            }
          }
        }

        &.mat-mdc-option-multiple .mat-pseudo-checkbox-checked {
          background-image: url('../../assets/solid-icons/solid-checkbox-checked-invalid.svg');
        }
      }

      &:not(:has(.search-input)) {
        padding: unset !important;

        .mat-mdc-option {
          border-radius: 0 !important;
        }
      }
    }

    &.disabled-dropdown.mat-mdc-menu-panel .mat-mdc-option.mdc-list-item--selected {
      background-color: unset !important;

      .mdc-list-item__primary-text {
        color: $neutral-black !important;
      }
    }

    &.mat-mdc-select-panel,
    &.mat-mdc-autocomplete-panel {
      .mdc-list-item--selected:not(.mat-mdc-option-multiple) {
        background-color: $primary-900 !important;

        .mdc-list-item__primary-text {
          color: $primary-400 !important;
        }
      }
    }

    .mat-mdc-option-multiple {
      .mat-mdc-option-pseudo-checkbox {
        border: none;
        width: 16px;
        height: 16px;
        margin-right: unset;
        background-image: url('../../assets/solid-icons/solid-checkbox-unchecked.svg');

        &::after {
          display: none;
        }

        &:hover {
          border: none;
        }
      }

      .mat-pseudo-checkbox-checked {
        background-image: url('../../assets/solid-icons/solid-checkbox-checked.svg');
      }
    }

    &.disabled-dropdown .mat-mdc-menu-content .mat-mdc-option-multiple .mat-mdc-option-pseudo-checkbox {
      display: none !important;
    }

    &.input-link {
      background: $tint-darkblue-2;
      border: 1px solid $tint-darkblue-20;
      border-radius: 3px;
      cursor: pointer;
      display: block;
      height: 40px;
      line-height: 40px;
      margin-top: 3px;
      padding: 0 16px;
      text-decoration: underline;

      a {
        color: $primary-color-vdw-blue;
      }
    }

    &button {
      &.invalid:not(:disabled) {
        background: $tint-red-6 !important;
        border: 1px solid $functional-color-red !important;
        color: $functional-color-red !important;
        box-shadow: unset !important;

        &:hover {
          background: $tint-red-10 !important;
        }

        &.cdk-mouse-focused {
          background: $tint-red-20 !important;
        }
      }
    }

    &.mat-mdc-form-field-hint {
      color: $neutral-400;
    }

    &.mat-mdc-form-field-error {
      &.button-validation {
        font-weight: 400;
        line-height: 1.125;
        margin-top: 5px;
      }

      &.form-validation-error {
        color: $functional-color-red;
        font-size: 12px;
        font-stretch: normal;
        font-style: normal;
        line-height: 12px;
        text-align: right;
        width: initial;
      }
    }

    &.mat-mdc-slide-toggle {
      --mdc-switch-track-width: 26px;
      --mdc-switch-track-height: 16px;
      --mdc-switch-track-shape: 8px;
      --mdc-switch-handle-width: 8px;
      --mdc-switch-handle-height: 8px;
      --mdc-switch-handle-shape: 6px;
      --mdc-switch-disabled-track-opacity: 1;
      --mdc-switch-disabled-handle-opacity: 1;
      --mdc-switch-unselected-track-color: transparent;
      --mdc-switch-unselected-focus-track-color: #{$neutral-950};
      --mdc-switch-unselected-pressed-track-color: #{$neutral-950};
      --mdc-switch-unselected-hover-track-color: #{$neutral-950};
      --mdc-switch-selected-track-color: #{$primary-400};
      --mdc-switch-selected-focus-track-color: #{$primary-500};
      --mdc-switch-selected-pressed-track-color: #{$primary-500};
      --mdc-switch-selected-hover-track-color: #{$primary-500};
      --mdc-switch-unselected-handle-color: #{$neutral-400};
      --mdc-switch-unselected-focus-handle-color: #{$primary-400};
      --mdc-switch-unselected-pressed-handle-color: #{$primary-400};
      --mdc-switch-unselected-hover-handle-color: #{$primary-400};
      --mdc-switch-selected-handle-color: #{$neutral-white};
      --mdc-switch-selected-focus-handle-color: #{$neutral-white};
      --mdc-switch-selected-pressed-handle-color: #{$neutral-white};
      --mdc-switch-selected-hover-handle-color: #{$neutral-white};
      --mdc-switch-disabled-unselected-track-color: #{$neutral-950};
      --mdc-switch-disabled-selected-track-color: #{$neutral-800};
      --mdc-switch-disabled-unselected-handle-color: #{$neutral-800};
      --mdc-switch-disabled-selected-handle-color: #{$neutral-white};

      display: flex !important;

      &.error {
        --mdc-switch-unselected-focus-track-color: transparent;
        --mdc-switch-unselected-pressed-track-color: transparent;
        --mdc-switch-unselected-hover-track-color: transparent;
        --mdc-switch-selected-track-color: #{$semantic-error-400};
        --mdc-switch-selected-focus-track-color: #{$semantic-error-400};
        --mdc-switch-selected-pressed-track-color: #{$semantic-error-400};
        --mdc-switch-selected-hover-track-color: #{$semantic-error-400};
        --mdc-switch-unselected-handle-color: #{$semantic-error-400};
        --mdc-switch-unselected-focus-handle-color: #{$semantic-error-400};
        --mdc-switch-unselected-pressed-handle-color: #{$semantic-error-400};
        --mdc-switch-unselected-hover-handle-color: #{$semantic-error-400};

        .mdc-switch:not(.mdc-switch--disabled) + label {
          color: $semantic-error-400;
        }
      }

      .mdc-form-field {
        .mdc-switch {
          border-radius: var(--mdc-switch-track-shape);

          .mdc-switch__track {
            border: 1px solid var(--mdc-switch-unselected-handle-color);
          }

          .mdc-switch__handle-track .mdc-switch__handle {
            .mdc-switch__shadow {
              box-shadow: none;
            }

            .mdc-switch__ripple {
              display: none;
            }

            .mdc-switch__icons {
              display: none;
            }
          }

          &.mdc-switch--selected {
            background-color: var(--mdc-switch-selected-track-color);

            .mdc-switch__track {
              border-color: var(--mdc-switch-selected-track-color);

              &::before {
                margin-left: 1px;
              }
            }

            .mdc-switch__handle-track {
              --mdc-switch-handle-width: 12px;
              --mdc-switch-handle-height: 12px;

              .mdc-switch__handle {
                margin-left: -2px;
              }
            }

            &:enabled:hover {
              background-color: var(--mdc-switch-selected-hover-track-color);

              .mdc-switch__track {
                border-color: var(--mdc-switch-selected-hover-track-color);
              }
            }

            &:enabled:focus {
              background-color: var(--mdc-switch-selected-focus-track-color);

              .mdc-switch__track {
                border-color: var(--mdc-switch-selected-focus-track-color);
              }
            }

            &:disabled {
              background-color: var(--mdc-switch-disabled-selected-track-color);
            }
          }

          &.mdc-switch--unselected {
            .mdc-switch__track::after {
              margin-left: -1px;
            }

            .mdc-switch__handle-track .mdc-switch__handle {
              margin-left: 4px !important;
            }

            &:enabled:hover {
              background-color: var(--mdc-switch-unselected-hover-track-color);

              .mdc-switch__track {
                border-color: var(--mdc-switch-unselected-hover-handle-color);
              }
            }

            &:enabled:focus {
              background-color: var(--mdc-switch-unselected-focus-track-color);

              .mdc-switch__track {
                border-color: var(--mdc-switch-unselected-focus-handle-color);
              }
            }

            &:disabled {
              background-color: var(--mdc-switch-disabled-unselected-track-color);
            }
          }

          &:disabled .mdc-switch__track {
            border-color: var(--mdc-switch-disabled-selected-track-color);
          }
        }

        label {
          padding-left: 8px;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0;
          color: $neutral-black;
          font-weight: $font-weight-regular;
          white-space: nowrap;
          -webkit-font-smoothing: antialiased;

          &:empty {
            display: none;
          }
        }
      }

      .mdc-switch--disabled + label {
        color: $neutral-600;
      }

      & * {
        transition-duration: 150ms !important;

        &::after,
        &::before {
          transition-duration: 150ms !important;
        }
      }
    }

    &.mat-mdc-radio-button {
      display: flex;
      align-items: flex-start;

      .mdc-form-field {
        .mdc-radio {
          --mdc-radio-unselected-icon-color: #{$neutral-400};
          --mdc-radio-unselected-focus-icon-color: #{$primary-400};
          --mdc-radio-unselected-hover-icon-color: #{$primary-400};
          --mdc-radio-unselected-pressed-icon-color: #{$primary-400};
          --mdc-radio-selected-icon-color: #{$primary-400};
          --mdc-radio-selected-focus-icon-color: #{$primary-500};
          --mdc-radio-selected-hover-icon-color: #{$primary-500};
          --mdc-radio-selected-pressed-icon-color: #{$primary-500};
          --mdc-radio-disabled-unselected-icon-color: #{$neutral-800};
          --mdc-radio-disabled-selected-icon-color: #{$neutral-700};
          --mdc-radio-disabled-unselected-icon-opacity: 1;
          --mdc-radio-disabled-selected-icon-opacity: 1;

          min-width: 16px;
          max-width: 16px;
          min-height: 16px;
          max-height: 16px;
          padding: 0;

          .mat-mdc-radio-touch-target,
          .mdc-radio__native-control,
          .mdc-radio__background {
            width: 100%;
            height: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            transform: none;
          }

          .mat-radio-ripple,
          .mdc-radio__background::before {
            display: none;
          }

          .mdc-radio__background {
            .mdc-radio__outer-circle {
              border-width: 1px;
            }

            .mdc-radio__inner-circle {
              transform: translate(-2px, -2px) scale(0);
              border-color: $neutral-white !important;
              transition:
                transform 280ms 0ms cubic-bezier(0, 0, 0.2, 1),
                border-color 0ms 0ms cubic-bezier(0, 0, 0.2, 1);
            }
          }

          .mdc-radio__native-control:checked {
            &:enabled + .mdc-radio__background .mdc-radio__outer-circle {
              background-color: var(--mdc-radio-selected-icon-color);
            }

            & + .mdc-radio__background .mdc-radio__inner-circle {
              transform: translate(-2px, -2px) scale(0.3);
            }
          }

          .mdc-radio__native-control:disabled {
            & + .mdc-radio__background .mdc-radio__outer-circle {
              background-color: $neutral-950;
            }

            & + .mdc-radio__background .mdc-radio__inner-circle {
              border-color: $neutral-950 !important;
            }

            &:checked + .mdc-radio__background .mdc-radio__outer-circle {
              background-color: var(--mdc-radio-disabled-selected-icon-color);
            }
          }

          &:hover .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
            background-color: var(--mdc-radio-selected-hover-icon-color);
          }
        }

        .mdc-radio--disabled + label {
          color: $neutral-600;
        }

        label {
          color: $neutral-black;
          padding-left: 8px;
          letter-spacing: 0;
          white-space: nowrap;
          line-height: 16px;
        }
      }
    }

    &.mat-mdc-radio-group.ng-invalid.ng-pristine.ng-touched .mat-mdc-radio-button .mdc-form-field .mdc-radio {
      --mdc-radio-unselected-icon-color: #{$semantic-error-400};
      --mdc-radio-unselected-focus-icon-color: #{$semantic-error-400};
      --mdc-radio-unselected-hover-icon-color: #{$semantic-error-400};
      --mdc-radio-unselected-pressed-icon-color: #{$semantic-error-400};
      --mdc-radio-selected-icon-color: #{$semantic-error-400};
      --mdc-radio-selected-focus-icon-color: #{$semantic-error-400};
      --mdc-radio-selected-hover-icon-color: #{$semantic-error-400};
      --mdc-radio-selected-pressed-icon-color: #{$semantic-error-400};

      &:not(.mdc-radio--disabled) + label {
        color: $semantic-error-400;
      }
    }

    &.mat-mdc-radio-group.radio-toggle-buttons {
      .mat-mdc-radio-button {
        position: relative;
        display: flex;
        align-items: center;
        height: 44px;
        padding: 0 16px;
        background-color: $tint-darkblue-2;
        border-color: $tint-darkblue-20;
        border-style: solid;
        border-width: 1px 0 1px 1px;
        font-size: 14px;

        .mdc-form-field {
          .mdc-radio .mat-mdc-radio-touch-target {
            position: absolute;
            height: 44px;
            top: -14px;
            left: -16px;
            width: calc(3 * 100%);
          }

          label {
            padding: 12px 0 12px 8px;
          }
        }

        &.mat-mdc-radio-checked {
          background-color: $tint-lightblue-30;
        }

        &:first-of-type {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }

        &:last-of-type {
          border-right-width: 1px;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }

        &:hover {
          &:not(:has(.mdc-radio--disabled)) {
            cursor: pointer;

            .mdc-form-field label {
              cursor: pointer;
            }
          }

          &:not(.mat-mdc-radio-checked):not(:has(.mdc-radio--disabled)) {
            background-color: $tint-lightblue-10-transparent;
          }
        }
      }

      padding-bottom: 22px;
    }

    &.mat-mdc-checkbox {
      display: flex !important;

      .mdc-form-field {
        width: 100%;

        .mdc-checkbox {
          --mdc-checkbox-unselected-icon-color: #{$neutral-400};
          --mdc-checkbox-unselected-focus-icon-color: #{$primary-400};
          --mdc-checkbox-unselected-hover-icon-color: #{$primary-400};
          --mdc-checkbox-unselected-pressed-icon-color: #{$primary-400};
          --mdc-checkbox-selected-icon-color: #{$primary-400};
          --mdc-checkbox-selected-focus-icon-color: #{$primary-500};
          --mdc-checkbox-selected-hover-icon-color: #{$primary-500};
          --mdc-checkbox-selected-pressed-icon-color: #{$primary-500};
          --mdc-checkbox-disabled-unselected-icon-color: #{$neutral-800};
          --mdc-checkbox-disabled-selected-icon-color: #{$neutral-700};

          min-width: 16px;
          max-width: 16px;
          min-height: 16px;
          max-height: 16px;
          padding: 0;

          .mat-mdc-checkbox-touch-target,
          .mdc-checkbox__native-control,
          .mdc-checkbox__background {
            width: 100%;
            height: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            transform: none;
          }

          .mdc-checkbox__ripple {
            display: none;
          }

          .mdc-checkbox__background {
            border-width: 1px;

            .mdc-checkbox__checkmark {
              scale: 1.14;

              .mdc-checkbox__checkmark-path {
                stroke-width: 0;
                fill: $neutral-white;
                scale: 1.5;
                d: path(
                  'M 6.66654 9.06668 L 10.5999 5.13335 C 10.7221 5.01112 10.8776 4.95001 11.0665 4.95001 C 11.2554 4.95001 11.411 5.01112 11.5332 5.13335 C 11.6554 5.25557 11.7165 5.41112 11.7165 5.60001 C 11.7165 5.7889 11.6554 5.94446 11.5332 6.06668 L 7.1332 10.4667 C 6.99987 10.6 6.84431 10.6667 6.66654 10.6667 C 6.48876 10.6667 6.3332 10.6 6.19987 10.4667 L 4.46654 8.73335 C 4.34431 8.61112 4.2832 8.45557 4.2832 8.26668 C 4.2832 8.07779 4.34431 7.92223 4.46654 7.80001 C 4.58876 7.67779 4.74431 7.61668 4.9332 7.61668 C 5.12209 7.61668 5.27765 7.67779 5.39987 7.80001 L 6.66654 9.06668 Z'
                );
              }
            }
          }

          .mdc-checkbox__mixedmark {
            --mdc-checkbox-selected-checkmark-color: #{$primary-400};
            --mdc-checkbox-disabled-selected-checkmark-color: #{$neutral-700};

            width: 10px;
            border-radius: 1px;
            scale: 0.67;
          }
        }

        label {
          width: 100%;
          overflow: hidden;
          text-align: left;
          font-size: 14px;
          color: $neutral-black;
          padding-left: 8px;
          letter-spacing: 0;
          line-height: 16px;
        }
      }

      &:hover .mdc-form-field .mdc-checkbox .mdc-checkbox__mixedmark {
        --mdc-checkbox-selected-checkmark-color: #{$primary-500};
      }

      &.invalid,
      &.ng-invalid.ng-pristine.ng-touched {
        .mdc-form-field .mdc-checkbox {
          --mdc-checkbox-unselected-icon-color: #{$semantic-error-400};
          --mdc-checkbox-unselected-focus-icon-color: #{$semantic-error-400};
          --mdc-checkbox-unselected-hover-icon-color: #{$semantic-error-400};
          --mdc-checkbox-unselected-pressed-icon-color: #{$semantic-error-400};
          --mdc-checkbox-selected-icon-color: #{$semantic-error-400};
          --mdc-checkbox-selected-focus-icon-color: #{$semantic-error-400};
          --mdc-checkbox-selected-hover-icon-color: #{$semantic-error-400};
          --mdc-checkbox-selected-pressed-icon-color: #{$semantic-error-400};

          .mdc-checkbox__mixedmark {
            --mdc-checkbox-selected-checkmark-color: #{$semantic-error-400};
          }
        }

        label {
          color: $semantic-error-400;
        }
      }

      &.mdc-checkbox--disabled {
        &:not(.mat-mdc-checkbox-checked) .mdc-checkbox__background {
          background-color: $neutral-950 !important;
        }

        .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background {
          border-color: var(--mdc-checkbox-disabled-selected-icon-color) !important;
          background-color: $neutral-950 !important;
        }

        label {
          color: $neutral-600;
        }
      }

      .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background {
        background-color: transparent !important;
      }
    }

    &.checkbox-toggle-buttons {
      display: flex;

      .mat-mdc-checkbox {
        display: flex;
        background-color: $primary-color-white;
        border-color: $tint-darkblue-20;
        border-style: solid;
        border-width: 1px 0 1px 1px;
        height: 44px;

        &.mat-mdc-checkbox-checked {
          background-color: $tint-lightblue-30;
        }

        &:first-of-type {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }

        &:last-of-type {
          border-right-width: 1px;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }

        .mdc-form-field {
          height: 100%;
          padding-left: 16px;

          .mat-mdc-checkbox-touch-target {
            width: 32px;
            height: 40px;
            top: -12px;
            left: -16px;
          }

          label {
            padding: 10px 16px 10px 8px;
          }
        }
      }
    }

    &fieldset {
      border: none;
      padding: 0;
      margin: 0;
    }

    &.custom-form-field > .mat-mdc-text-field-wrapper > .mat-mdc-form-field-flex > .mat-mdc-form-field-infix,
    &.custom-form-field.mat-form-field-invalid > .mat-mdc-text-field-wrapper > .mat-mdc-form-field-flex > .mat-mdc-form-field-infix,
    &.custom-form-field.mat-form-field-invalid.mat-form-field-appearance-fill > .mat-mdc-text-field-wrapper > .mat-mdc-form-field-flex > .mat-mdc-form-field-infix,
    &.custom-form-field.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float.mat-form-field-invalid
      > .mat-mdc-text-field-wrapper
      > .mat-mdc-form-field-flex
      > .mat-mdc-form-field-infix {
      border: none !important;
      background-color: unset !important;
      padding-left: 0 !important;
    }

    &.mat-form-field-readonly {
      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
          .mat-mdc-form-field-infix {
            background: $tint-darkblue-6 !important;
            border-color: $tint-darkblue-6 !important;
          }
        }
      }

      .mat-mdc-input-element:read-only {
        color: $tint-darkblue-50-transparent !important;
        cursor: default;
      }

      .mat-mdc-form-field-icon-suffix span {
        color: $tint-darkblue-50-transparent !important;
      }
    }

    &.form-field-skeleton-wrapper {
      width: 100%;
      min-width: 0;
    }

    &.blurry-content-fix {
      mat-form-field mat-label,
      .mat-mdc-form-field-infix mat-label,
      mat-datepicker-toggle button,
      vdw-datepicker-form-input .mdc-text-field__input,
      .mdc-text-field__input {
        will-change: unset !important;
        -webkit-filter: blur(0) !important;
        backface-visibility: hidden !important;
        transform: translateZ(0) !important;
        -webkit-font-smoothing: subpixel-antialiased !important;
      }
    }

    &.mat-mdc-form-field-error-wrapper mat-error:not(:first-child) {
      display: none;
    }
  }

  .bms-theme.mat-mdc-form-field-type-mat-select .mdc-text-field--invalid + .mat-mdc-select-panel .mat-mdc-option {
    &:hover:not(.mdc-list-item--disabled):not(.mdc-list-item--selected):not(.mat-mdc-option-active) {
      background-color: $semantic-error-900 !important;

      .mdc-list-item__primary-text {
        color: $semantic-error-400 !important;
      }
    }
  }
}
