@use '../vdw-variables' as *;

@mixin badge {
  .mat-badge-warn .mat-badge-content {
    color: $primary-color-white;
    background-color: $functional-color-red;
  }

  .mat-badge-small .mat-badge-content {
    width: 16px;
    height: 16px;
    font-weight: $font-weight-bold;
    font-family: $mat-font-family;
    font-size: 12px;
  }

  .bms-theme {
    .mat-badge-content {
      --mat-badge-small-size-container-overlap-offset: 0;
      --mat-badge-small-size-text-size: 10px;
      --mat-badge-small-size-container-size: 10px;

      font-weight: 600;
      overflow: visible;
      line-height: 10px;
      top: -4px !important;
      right: -4px !important;
      left: unset !important;
      color: $semantic-error-400;
      background-color: transparent;
    }
  }
}
