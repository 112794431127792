@use 'variables' as *;

@mixin dialogs {
  .finishing-image-label-dialog,
  .finishing-text-label-dialog,
  .change-placeholder-parts,
  .confirm-production-schedule-dialog,
  .changelog-dialog {
    max-height: 90vh;
    width: 800px;

    .mat-mdc-dialog-container .mat-mdc-dialog-surface {
      display: flex;
      overflow: unset;
    }

    @include breakpoint-xsmall() {
      max-width: 100vw !important;
    }
  }

  .confirm-production-schedule-dialog {
    width: 640px;
  }

  .onboarding-backdrop {
    &::before {
      width: $side-navigation-width;
    }

    &::after {
      width: calc(100% - #{$side-navigation-width});
      left: $side-navigation-width;
      background-color: $tint-vdw-blue-20-transparent;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 100%;
    }
  }

  .onboarding-dialog {
    @media (min-width: $breakpoint-gt-md) {
      width: 384px;
      min-width: 384px;
    }

    @media (max-width: $breakpoint-gt-md) {
      margin-right: 16px;
    }

    .dialog-header {
      height: 72px;
    }
  }

  .texstate-onboarding-dialog {
    @media (min-width: $breakpoint-gt-md) {
      width: 400px;
      min-width: 400px;
    }

    @media (max-width: $breakpoint-gt-md) {
      margin-right: 16px;
    }

    .dialog-header {
      height: 98px;
    }
  }

  .onboarding-dialog,
  .texstate-onboarding-dialog,
  .edit-drawing-in-path {
    .mat-mdc-dialog-surface {
      overflow: hidden;
      position: unset;
    }
  }

  .production-schedule-errors-and-warnings-dialog {
    --mdc-dialog-container-elevation: 0 16px 48px #{$tint-darkblue-30-transparent} !important;

    width: 400px;
    min-width: 400px;
  }

  .add-pattern-dialog {
    width: 480px;
    height: unset !important;
  }

  .box-shadow-none-dialog {
    --mdc-dialog-container-elevation: none;
  }

  .overflow-hidden-dialog .mat-mdc-dialog-surface {
    overflow: hidden;
  }
}
