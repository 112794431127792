@use '../vdw-variables' as *;

@mixin cards {
  .mat-mdc-card,
  mat-expansion-panel.expansion-panel-as-card {
    --mdc-elevated-card-container-shape: 6px;
    --mdc-elevated-card-container-color: #{$primary-color-white};
  }

  .mat-mdc-card {
    --mat-card-title-text-font: #{$mat-font-family};
    --mat-card-title-text-weight: #{$font-weight-semibold};
    --mat-card-title-text-tracking: -0.05px;
    --mdc-card-title-text-letter-spacing: -0.05px;
    --mat-card-title-text-size: 20px;

    margin-bottom: 16px;

    .mat-mdc-card-header {
      border-bottom: 1px solid $tint-darkblue-20;
      height: $card-header-height;
      min-height: $card-header-height;
      padding: 0 24px;

      .mat-mdc-card-avatar {
        margin-bottom: 0;

        &:not(.mat-mdc-icon-button) {
          height: 36px;
          width: 36px;

          .mat-icon {
            height: 36px;
            width: 36px;
          }
        }

        & ~ .mat-mdc-card-header-text .mat-mdc-card-title,
        & ~ .mat-mdc-card-header-text .mat-mdc-card-subtitle {
          padding: 0;
          margin: 0 16px;
        }
      }
    }

    .mat-mdc-card-title {
      color: $primary-color-dark-blue;
    }

    .mat-mdc-card-content {
      font-size: 14px;
      padding: 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }

      > article {
        padding: 24px;
      }
    }
  }

  mat-expansion-panel.expansion-panel-as-card,
  .mat-mdc-card-outlined {
    --mdc-outlined-card-outline-width: 1px;
    --mdc-outlined-card-outline-color: #{$tint-darkblue-20};
  }

  mat-expansion-panel {
    &.mat-expansion-panel:not([class*='mat-elevation-z']) {
      box-shadow: none;
    }

    article.content {
      margin-top: 20px;
    }
  }

  mat-expansion-panel.expansion-panel-as-card {
    border: var(--mdc-outlined-card-outline-width) solid var(--mdc-outlined-card-outline-color);
    background: var(--mdc-elevated-card-container-color);
    border-radius: var(--mdc-elevated-card-container-shape);
    margin-bottom: 16px;
  }

  .expansion-panel-additional-options {
    .mat-expansion-panel-header .mat-expansion-panel-header-title {
      color: $primary-color-dark-blue;
      font-size: 20px;
      -webkit-font-smoothing: antialiased;
    }

    &.mat-expanded > .mat-expansion-panel-header {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: solid 1px $tint-darkblue-20;
    }
  }

  span.mat-expansion-indicator::after {
    border: none;
    content: url('/assets/icons/24px/chevron-down.svg');
    transform: initial;
    height: 24px;
  }

  @include breakpoint-xsmall() {
    .mat-mdc-card {
      --mdc-outlined-card-outline-width: 0;
      --mdc-elevated-card-container-shape: 0;

      border-radius: 0;

      .mat-mdc-card-content > article {
        padding: 16px 24px 24px 24px;
      }
    }

    .link-form-input {
      width: 100%;
    }

    .fixed-toolbar-actions {
      box-shadow: unset;
      border-top: 1px solid $tint-darkblue-20;
    }
  }
}
