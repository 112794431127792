@use '../../../scss/vdw-variables.scss' as *;

.chips-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  margin-bottom: 16px;
}

::ng-deep {
  .mat-mdc-menu-panel {
    padding: 0 !important;
  }
}

.no-wrap {
  flex-wrap: nowrap !important;
}

.chips-container-small {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  height: 24px;
  gap: 8px;
  width: 100%;
}

.quantity-dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 2px;
  min-width: 24px;

  p {
    color: $neutral-400;
  }

  &:hover {
    background: $neutral-900;
  }

  &.menu-openned {
    background: $neutral-850;
  }

  &.error-quantity {
    p {
      color: $semantic-error-400;
    }

    &:hover {
      background: $semantic-error-900;
    }

    &.menu-openned {
      background: $semantic-error-850;
    }
  }
}

.chips-small {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .chip-content .input-chip p {
    width: 100%;
  }
}

::ng-deep.mat-mdc-menu-panel {
  width: fit-content;
  display: flex;
  height: auto;
  min-width: 0;
  padding: 12px;
  margin-top: 4px;
  margin-bottom: 4px;

  .chips-container-small {
    flex-wrap: nowrap;
    flex-direction: column;
    height: unset;
    max-height: 500px;
  }

  .mat-mdc-menu-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }
}
