/* You can add global styles to this file, and also import other style files */
.refresh-token-container {
  border: 0;
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
}

#toast-container {
  &.toast-bottom-right {
    right: 17px;
    bottom: 11px;
  }
}

@media (max-width: 599.9px) {
  #toast-container.toast-bottom-right {
    left: 50%;
    bottom: 0;
    margin-left: -173px;
  }
}
