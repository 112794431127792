mat-icon {
  &.icon-52 {
    width: 52px;
    height: 52px;
    font-size: 52px;
    min-width: 52px;
    min-height: 52px;
  }

  &.icon-36 {
    width: 36px;
    height: 36px;
    font-size: 36px;
    min-width: 36px;
    min-height: 36px;
  }

  &.icon-30 {
    width: 30px;
    height: 30px;
    font-size: 30px;
    min-width: 30px;
    min-height: 30px;
  }

  &.icon-24 {
    width: 24px;
    height: 24px;
    font-size: 24px;
    min-width: 24px;
    min-height: 24px;
  }

  &.icon-20 {
    width: 20px;
    height: 20px;
    font-size: 20px;
    min-width: 20px;
    min-height: 20px;
  }

  &.icon-16 {
    width: 16px;
    height: 16px;
    font-size: 16px;
    min-width: 16px;
    min-height: 16px;
  }

  &.icon-12 {
    width: 12px;
    height: 12px;
    font-size: 12px;
    min-width: 12px;
    min-height: 12px;
  }
}
