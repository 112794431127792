@use '../vdw-variables' as *;

@mixin lists {
  .mat-mdc-list-base {
    --mdc-list-list-item-label-text-font: #{$mat-font-family};
    --mdc-list-list-item-supporting-text-font: #{$mat-font-family};
    --mdc-list-list-item-label-text-size: 14px;
    --mdc-list-list-item-label-text-tracking: -0.05px;
    --mdc-list-list-item-supporting-text-tracking: -0.05px;
  }
}
