@use '../vdw-variables' as *;

@mixin buttons {
  :root {
    --mdc-typography-button-font-family: #{$mat-font-family};
    --mdc-typography-button-letter-spacing: -0.05px;
    --mdc-outlined-button-label-text-tracking: -0.05px;
    --mdc-filled-button-label-text-tracking: -0.05px;
    --mdc-text-button-label-text-tracking: -0.05px;
    --mdc-typography-button-line-height: 36px;
    --mdc-typography-button-font-size: 14px;
    --mdc-typography-button-font-weight: #{$font-weight-bold};
    --mdc-filled-button-label-text-weight: #{$font-weight-bold};
    --mdc-outlined-button-label-text-weight: #{$font-weight-bold};
    --mdc-text-button-label-text-weight: #{$font-weight-bold};
  }

  .mat-mdc-button-ripple {
    display: none;
  }

  .mat-mdc-button-persistent-ripple {
    --mat-mdc-button-persistent-ripple-color: $primary-color-black;
    --mat-mdc-button-ripple-color: transparent;
  }

  .mdc-button {
    line-height: var(--mdc-typography-button-line-height) !important;
    white-space: nowrap;
    padding: 0 16px !important;
  }

  .mdc-button,
  .mdc-icon-button {
    .mat-icon {
      vertical-align: middle;
    }

    &:hover,
    &.cdk-mouse-focused {
      .mat-mdc-button-persistent-ripple {
        --mat-mdc-button-persistent-ripple-color: transparent;
      }
    }
  }

  .mat-mdc-unelevated-button,
  .mat-mdc-outlined-button {
    padding: 0 24px !important;
    -webkit-font-smoothing: antialiased;

    &.mat-button-with-icon {
      padding-right: 16px !important;

      .mat-icon {
        margin-top: -4px;
        margin-left: 8px;
      }
    }

    .mat-icon {
      height: 24px;
      width: 24px;
      min-height: 24px;
      min-width: 24px;
    }

    &[disabled] .mat-icon .fill-color {
      fill: $tint-darkblue-30;
    }
  }

  .mat-mdc-unelevated-button.mdc-button {
    --mdc-filled-button-label-text-color: #{$primary-color-white};
    --mdc-filled-button-container-color: #{$primary-color-vdw-blue};
    --mdc-filled-button-container-height: 44px;
    --mdc-filled-button-container-shape: 4px;

    height: var(--mdc-filled-button-container-height);

    .mat-icon .fill-color {
      fill: $primary-color-white;
    }

    &.medium {
      --mdc-filled-button-container-height: 40px;
    }

    &:disabled {
      --mdc-filled-button-disabled-container-color: #{$tint-darkblue-30};
      --mdc-filled-button-disabled-label-text-color: #{$primary-color-white};
    }

    &:not([disabled]) {
      box-shadow: 0 2px 28px $tint-vdw-blue-20-transparent !important;
    }

    &:hover:not([disabled]):not(.invalid) {
      --mdc-filled-button-container-color: #{$tint-vdw-blue-hover};

      &.cdk-mouse-focused {
        background: $primary-color-dark-blue !important;
      }
    }
  }

  .mat-mdc-outlined-button.mdc-button {
    --mdc-outlined-button-outline-color: #{$primary-color-vdw-blue};
    --mdc-outlined-button-label-text-color: #{$primary-color-vdw-blue} !important;
    --mdc-outlined-button-container-shape: 4px;
    --mdc-outlined-button-container-height: 44px;

    background-color: $primary-color-white;
    height: var(--mdc-outlined-button-container-height);

    &.medium {
      --mdc-outlined-button-container-height: 40px;
    }

    &:disabled {
      --mdc-outlined-button-disabled-label-text-color: #{$tint-darkblue-30};
      --mdc-outlined-button-disabled-outline-color: #{$tint-darkblue-30};
    }

    &:hover:not([disabled]):not(.invalid) {
      background-color: $tint-vdw-blue-10;

      &.cdk-mouse-focused {
        background: $tint-vdw-blue-20;
      }
    }

    &.mat-warn {
      --mdc-outlined-button-label-text-color: #{$functional-color-red} !important;
      --mdc-outlined-button-outline-color: #{$functional-color-red};

      background-color: $tint-red-6;

      &:not([disabled]):not(.invalid):hover {
        background-color: $tint-red-10;

        &.cdk-mouse-focused {
          background: $tint-red-20 !important;
        }
      }

      .mat-icon {
        margin-left: 0;
      }
    }

    &.mat-warning {
      --mdc-outlined-button-label-text-color: #{$functional-color-orange} !important;
      --mdc-outlined-button-outline-color: #{$tint-orange-70};

      background-color: $tint-orange-6;

      &:not([disabled]):not(.invalid):hover {
        background-color: $tint-orange-10;

        &.cdk-mouse-focused {
          background: $tint-orange-20 !important;
        }
      }
    }
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 40px;

    padding: 0;
    font-size: unset;

    .mat-mdc-button-persistent-ripple {
      border-radius: inherit;
    }

    &:disabled {
      background: $tint-darkblue-6;

      .mat-icon path {
        fill: $tint-darkblue-30-transparent;
      }
    }
  }

  .mat-mdc-form-field-icon-suffix,
  .mat-mdc-form-field-icon-prefix {
    button[disabled].mat-mdc-icon-button {
      background: none;
    }
  }

  .mat-mdc-icon-button.mat-mdc-button-base,
  .mat-datepicker-toggle {
    line-height: initial;
    padding: 0 !important;
    width: var(--mdc-icon-button-state-layer-size);
    height: var(--mdc-icon-button-state-layer-size);

    &.mat-icon-action-button-sm-without-border {
      --mdc-icon-button-state-layer-size: 24px;

      .mat-icon {
        width: var(--mdc-icon-button-state-layer-size);
        height: var(--mdc-icon-button-state-layer-size);
      }

      .fill-color {
        fill: $primary-color-dark-blue;
      }

      .stroke-color {
        stroke: $primary-color-dark-blue;
      }

      &:not([disabled]):not(.invalid):hover {
        .fill-color {
          fill: $primary-color-vdw-blue !important;
        }

        .stroke-color {
          stroke: $primary-color-vdw-blue !important;
        }
      }

      &:not([disabled]).cdk-mouse-focused {
        .fill-color {
          fill: $primary-color-dark-blue;
        }

        .stroke-color {
          fill: $primary-color-dark-blue;
        }
      }

      &[disabled] {
        .fill-color {
          fill: $tint-darkblue-20;
        }

        .stroke-color {
          fill: $tint-darkblue-20;
        }
      }
    }

    &.mat-icon-action-button-lg-with-border,
    &.mat-icon-action-button-md-with-border {
      border: 1px solid $tint-darkblue-20;
      border-radius: 3px;
      transition:
        border 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
        background-color 280ms cubic-bezier(0.4, 0, 0.2, 1);
      transition-property: border, background-color;
      transition-duration: 0.4s, 280ms;
      transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1), cubic-bezier(0.4, 0, 0.2, 1);
      transition-delay: 0s, 0s;

      .mat-icon {
        border: none !important;
        width: 24px !important;
        height: 24px !important;
      }
    }

    &.mat-icon-action-button-md-with-border {
      --mdc-icon-button-state-layer-size: 36px;
    }

    &.mat-icon-action-button-lg-with-border {
      --mdc-icon-button-state-layer-size: 44px;
    }

    &.mat-icon-action-button-sm {
      --mdc-icon-button-state-layer-size: 26px;

      border: 1px solid $tint-darkblue-20;
      border-radius: 3px;

      .mat-icon {
        width: 24px;
        height: 24px;
      }
    }

    &.mat-icon-action-button-lg-with-border,
    &.mat-icon-action-button-sm {
      &:not([disabled]):not(.invalid):not(.select-mode-active):hover {
        background: $tint-vdw-blue-6;
      }

      &:not([disabled]):not(.select-mode-active):not(.select-mode-inactive):not(.disable-focus).cdk-mouse-focused {
        background: $tint-vdw-blue-20 !important;
        border-color: $primary-color-dark-blue;
      }

      &[disabled] {
        background: $tint-darkblue-6;
        border-color: $tint-darkblue-6;
      }
    }
  }

  .mat-mdc-dialog-container header {
    .mat-mdc-icon-button .mat-icon {
      border: none;
    }
  }

  .select-mode-active {
    background: $tint-vdw-blue-10 !important;
    border-color: $primary-color-dark-blue !important;
  }

  .select-mode-inactive {
    background: $primary-color-white !important;
    border-color: $tint-darkblue-20 !important;
  }

  .mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
    height: 44px;
    border-radius: 3px;
    border: 1px solid $tint-darkblue-20;

    .mat-button-toggle {
      & + .mat-button-toggle {
        border-left: 1px solid $tint-darkblue-20;
      }

      &.mat-button-toggle-checked {
        background-color: $tint-lightblue-30;
      }

      &:not(.mat-button-toggle-disabled):not(.mat-button-toggle-checked):hover {
        background-color: $tint-lightblue-10;
        opacity: 1;
      }

      .mat-button-toggle-label-content {
        line-height: 42px;
        font-size: 14px;
        color: $primary-color-dark-blue;
        font-weight: $font-weight-regular;
        padding: 0 16px;
        font-family: $mat-font-family;
      }

      .mat-button-toggle-focus-overlay {
        background-color: unset;
        border: unset;
      }
    }
  }

  .bms-theme {
    .mat-mdc-button-base {
      --mdc-typography-button-line-height: 16px;
      --mdc-typography-button-font-weight: #{$font-weight-semibold};
      --mdc-filled-button-label-text-weight: #{$font-weight-semibold};
      --mdc-outlined-button-label-text-weight: #{$font-weight-semibold};
      --mdc-text-button-label-text-weight: #{$font-weight-semibold};

      .mat-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      &.mat-mdc-unelevated-button,
      &.mat-mdc-outlined-button,
      &.mat-mdc-button {
        height: fit-content;
        padding: 8px 10px !important;

        &:has(vdw-progress-spinner) {
          padding: 8px 8px 8px 10px !important;
        }

        .mat-icon svg path {
          fill: $primary-300;
        }

        .mat-mdc-button-touch-target {
          height: 32px;
        }

        > .mat-icon {
          width: 16px;
          height: 16px;
          min-width: 16px;
          min-height: 16px;
        }

        &.compact {
          --mdc-typography-button-font-size: 12px;
          --mdc-outlined-button-label-text-size: 12px;
          --mdc-typography-button-line-height: 12px;

          padding: 6px 8px !important;
          border-radius: 2px;

          .mat-mdc-button-touch-target {
            height: 24px;
          }

          > .mat-icon {
            width: 12px;
            height: 12px;
            min-width: 12px;
            min-height: 12px;

            svg {
              width: 12px;
              height: 12px;
            }
          }
        }
      }

      &.mat-mdc-unelevated-button {
        --mdc-filled-button-container-color: #{$primary-400};
        --mdc-filled-button-label-text-color: #{$neutral-white};

        .mat-icon svg path {
          fill: $primary-400 !important;
        }

        &:disabled {
          --mdc-filled-button-disabled-container-color: #{$neutral-900};
          --mdc-filled-button-disabled-label-text-color: #{$neutral-600};

          border: 1px solid $neutral-800;
        }

        &:not([disabled]) {
          box-shadow: none !important;
        }

        &:hover:not([disabled]):not(.invalid) {
          --mdc-filled-button-container-color: #{$primary-500};

          &.cdk-mouse-focused {
            background-color: var(--mdc-filled-button-container-color) !important;
          }
        }

        &:active:not([disabled]):not(.invalid) {
          --mdc-filled-button-container-color: #{$primary-300};
        }

        &.error {
          --mdc-filled-button-container-color: #{$semantic-error-400};

          &:hover:not([disabled]):not(.invalid) {
            --mdc-filled-button-container-color: #{$semantic-error-500};
          }

          &:active:not([disabled]):not(.invalid) {
            --mdc-filled-button-container-color: #{$semantic-error-300};
          }
        }
      }

      &.mat-mdc-outlined-button {
        --mdc-outlined-button-outline-color: #{$primary-700};
        --mdc-outlined-button-label-text-color: #{$primary-300} !important;

        padding: 7px 9px !important;

        &.compact {
          padding: 5px 7px !important;
        }

        &:has(vdw-progress-spinner) {
          padding: 7px 7px 7px 9px !important;

          &.compact {
            padding: 5px 5px 5px 7px !important;
          }
        }

        &:disabled {
          --mdc-outlined-button-disabled-label-text-color: #{$neutral-600};
          --mdc-outlined-button-disabled-outline-color: #{$neutral-800};

          background-color: $neutral-900;
        }

        &:hover:not([disabled]):not(.invalid) {
          background-color: $primary-900;
        }

        &:active:not([disabled]):not(.invalid) {
          --mdc-outlined-button-outline-color: #{$primary-300};

          background-color: $primary-850;
        }
      }

      &.mat-mdc-button {
        --mdc-text-button-label-text-color: #{$primary-300} !important;

        .mat-icon svg path {
          fill: var(--mdc-text-button-label-text-color);
        }

        &:disabled {
          --mdc-text-button-disabled-label-text-color: #{$neutral-600};

          .mat-icon svg path {
            fill: var(--mdc-text-button-disabled-label-text-color);
          }
        }

        &:hover {
          background-color: $primary-900;
        }

        &:active {
          background-color: $primary-850;
        }

        &.menu-item-button {
          --mdc-text-button-label-text-color: #{$neutral-black} !important;

          justify-content: left;
        }
      }

      &.mat-mdc-icon-button {
        --mdc-icon-button-state-layer-size: 32px;

        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        .mat-mdc-button-touch-target {
          width: 32px;
          height: 32px;
        }

        &.open {
          background-color: $primary-850 !important;

          .mat-icon svg path {
            fill: $primary-300;
          }
        }

        &:hover {
          background-color: $primary-900 !important;

          .mat-icon svg path {
            fill: $primary-400;
          }
        }

        &:active {
          background: $primary-850 !important;

          .mat-icon svg path {
            fill: $primary-300 !important;
          }
        }

        &.mat-icon-action-button-sm-with-border {
          --mdc-icon-button-state-layer-size: 20px;

          padding: 4px !important;
          border-radius: 2px;
          background-color: $neutral-white;
          box-shadow: 0 0 3px 0 $tint-neutral-black-20-transparent;

          .mat-icon {
            width: 100%;
            height: 100%;

            svg path {
              fill: $neutral-400;
            }
          }

          &:hover {
            background-color: $neutral-white !important;

            .mat-icon svg path {
              fill: $primary-400;
            }
          }

          &:active,
          &.open {
            background-color: $neutral-white !important;

            .mat-icon svg path {
              fill: $primary-300;
            }
          }
        }
      }
    }

    &.split-button {
      display: flex;
      flex-direction: row;

      > .mat-mdc-unelevated-button:first-child,
      > .mat-mdc-outlined-button:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      > .mat-mdc-unelevated-button:last-child,
      > .mat-mdc-outlined-button:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        min-width: unset;

        .mat-icon {
          margin: 0;
        }
      }

      .mat-mdc-unelevated-button {
        &:last-child {
          padding: 8px !important;

          &.compact {
            padding: 6px !important;
          }

          &.open {
            --mdc-filled-button-container-color: #{$primary-500};
          }

          .mat-icon svg path {
            fill: var(--mdc-filled-button-label-text-color) !important;
          }

          &:disabled .mat-icon svg path {
            fill: var(--mdc-filled-button-disabled-label-text-color) !important;
          }
        }
      }

      .mat-mdc-outlined-button {
        &:first-child {
          border-right: none;
          padding-right: 10px !important;

          &.compact {
            padding-right: 8px !important;
          }

          &:has(vdw-progress-spinner) {
            padding-right: 8px !important;

            &.compact {
              padding-right: 6px !important;
            }
          }
        }

        &:last-child {
          border-left: none;
          padding: 7px 7px 7px 8px !important;

          &.compact {
            padding: 5px 5px 5px 6px !important;
          }

          &.open {
            background-color: $primary-900;
          }

          .mat-icon svg path {
            fill: var(--mdc-outlined-button-label-text-color);
          }

          &:disabled .mat-icon svg path {
            fill: var(--mdc-outlined-button-disabled-label-text-color);
          }
        }
      }

      .mat-divider {
        transition: border 280ms cubic-bezier(0.4, 0, 0.2, 1);
      }

      &:has(.mat-mdc-unelevated-button) {
        --mat-divider-color: #{$primary-600};
      }

      &:has(.mat-mdc-outlined-button) {
        --mat-divider-color: #{$primary-700};
      }

      &:has(.mat-mdc-outlined-button:active) {
        --mat-divider-color: #{$primary-300};
      }

      &:has(:disabled) {
        --mat-divider-color: #{$neutral-800};
      }

      &.in-progress {
        pointer-events: none;
      }
    }

    vdw-progress-button {
      .mat-mdc-button-base {
        min-width: 64px !important;

        .mdc-button__label {
          width: 100%;

          .items-before-and-with-label {
            justify-content: center;
            flex-direction: row-reverse !important;
            gap: 6px;

            .mat-mdc-progress-spinner {
              --mdc-circular-progress-active-indicator-color: #{$primary-300};

              position: relative;
              top: unset;
              left: unset;
              transform: unset;
            }
          }
        }

        &:disabled .button-text {
          color: $neutral-600 !important;
        }

        &.compact .items-before-and-with-label {
          gap: 4px;
        }

        &:has(.mat-mdc-progress-spinner) {
          &.mat-mdc-unelevated-button {
            padding-right: 8px !important;

            &.compact {
              padding-right: 6px !important;
            }
          }

          &.mat-mdc-outlined-button {
            padding-right: 7px !important;

            &.compact {
              padding-right: 5px !important;
            }
          }
        }
      }
    }
  }
}
