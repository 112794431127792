@use 'vdw-variables' as *;

h1 {
  font-size: 40px;
  color: $primary-color-dark-blue;
  font-weight: $font-weight-bold;
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

h2 {
  font-size: 20px;
  font-weight: $font-weight-bold;
  color: $primary-color-dark-blue;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  line-height: 22px;
}

h3 {
  font-size: 18px;
  font-weight: $font-weight-bold;
  color: $primary-color-dark-blue;
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

h4,
.h4 {
  font-size: 16px;
  color: $primary-color-dark-blue;
  font-weight: $font-weight-bold;
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

.b1 {
  font-size: 14px !important;
  color: $primary-color-dark-blue;
  margin: 0;
  line-height: 16px;
}

.b2 {
  font-size: 12px;
  line-height: 12px;
  color: $primary-color-dark-blue;
  margin: 0;
}

.micro {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: $font-weight-semibold;
  color: $primary-color-dark-blue;
  line-height: 12px;
}

.url {
  text-decoration: underline !important;
  color: $primary-color-vdw-blue !important;
  font-weight: $font-weight-semibold !important;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;

  &:hover {
    color: $tint-vdw-blue-hover !important;
  }

  &:visited {
    color: $primary-color-dark-blue !important;
  }

  &.error {
    color: $functional-color-red !important;
  }

  &.warning {
    color: $functional-color-orange !important;
  }

  &.disabled {
    color: $tint-darkblue-50 !important;
  }
}

.main-title {
  font-size: 36px;
  line-height: 42px;
  font-weight: $font-weight-semibold;
}

.subtitle {
  font-size: 16px;
  line-height: 20px;
  font-weight: $font-weight-regular;
  color: $neutral-400;
}

.multi-line {
  line-height: 132%;
}

.font-weight-light {
  font-weight: $font-weight-light;
}

.font-weight-regular {
  font-weight: $font-weight-regular;
}

.font-weight-bold {
  font-weight: $font-weight-bold !important;
}

.font-weight-semibold {
  font-weight: $font-weight-semibold;
}

.font-weight-black {
  font-weight: $font-weight-black;
}

.hint {
  color: $tint-darkblue-50;
}

.copyright-text {
  font-size: 11px;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.italic {
  font-style: italic;
}

@media (max-width: $breakpoint-lt-md) {
  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
    font-weight: $font-weight-bold;
  }

  h3 {
    font-size: 18px !important;
  }

  h4 {
    font-weight: $font-weight-semibold;
  }
}

.bms-theme {
  h2 {
    color: $neutral-black;
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
  }

  .b1 {
    color: $neutral-black;
  }
}
