@use '../vdw-variables' as *;

@mixin datepicker {
  .datepicker-header {
    .title {
      color: $neutral-black !important;
    }

    .mdc-button {
      padding: 0 8px !important;
    }
  }

  .mat-datepicker-popup {
    margin-top: 8px;
  }

  .mat-datepicker-content {
    border: 1px solid $tint-darkblue-20;
    border-radius: 4px;
    box-shadow: 0 16px 48px $tint-vdw-blue-10-transparent;
  }

  .mat-calendar {
    width: 240px !important;
    height: unset !important;
  }

  .mat-calendar-body-selected {
    background-color: none;

    &.mat-calendar-body-today {
      box-shadow: unset !important;
    }
  }

  .mat-calendar-body {
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: $neutral-900;
    }
  }

  .mat-calendar-content {
    padding: 8px !important;
  }

  .mat-calendar-body-cell {
    &.mat-calendar-body-active {
      .mat-calendar-body-cell-content {
        color: $primary-400;
        background-color: $primary-900;

        &.mat-focus-indicator {
          color: $neutral-white;
          background-color: $primary-400;
        }
      }
    }

    .mat-calendar-body-today {
      background-color: $primary-900 !important;
      border-color: unset !important;
      color: $primary-300 !important;
    }
  }

  .mat-calendar-body-cell-content {
    &:not(.mat-calendar-body-selected) {
      color: $neutral-black;
    }

    &.mat-calendar-body-selected {
      color: $neutral-black;
      background-color: $neutral-white;
    }
  }

  [aria-hidden='true'] .mat-calendar-body-label {
    display: none;
  }

  .mat-calendar-body-label {
    visibility: hidden;
  }

  .mat-calendar-table-header {
    .mat-calendar-table-header-divider {
      display: none;
    }

    th:not(.mat-calendar-table-header-divider) {
      padding-bottom: 12px;
      font-size: 16px;
      color: $neutral-300;
    }
  }

  .mat-calendar-body-in-range {
    &.mat-calendar-body-range-start:not(.mat-calendar-body-in-comparison-range)::before,
    &.mat-calendar-body-range-start::after {
      left: unset;
      right: 0;
    }

    .mat-calendar-body-cell-content {
      color: $neutral-white;
    }

    .mat-calendar-body-today {
      border-color: $primary-400 !important;
    }

    .mat-calendar-body-cell.mat-calendar-body-active .mat-calendar-body-cell-content {
      background-color: $primary-400 !important;
    }

    .mat-calendar-body-cell-content,
    .mat-calendar-body-cell-content.mat-calendar-body-active,
    &::before {
      color: $neutral-white !important;
      background-color: $primary-400 !important;
    }

    .mat-calendar-body-in-preview-start {
      .mat-calendar-body-cell-content {
        background-color: $primary-400 !important;
      }

      .mat-calendar-body-selected {
        background-color: $primary-400 !important;
      }
    }
  }

  .mat-calendar-body-in-preview .mat-calendar-body-cell-preview {
    border-color: $primary-400 !important;
  }

  .mat-datepicker-actions {
    justify-content: space-between !important;

    button {
      color: $primary-300 !important;
    }
  }
}
