@use '../vdw-variables' as *;

@mixin dialogs {
  :root {
    --mdc-dialog-container-elevation: 0 17px 9px #{$tint-vdw-blue-10-transparent};
    --mdc-dialog-container-shape: 4px;
    --mdc-dialog-subhead-font: #{$mat-font-family};
  }

  mat-dialog-container .mat-mdc-dialog-surface {
    header {
      padding: 0 24px;
      min-height: 68px;
      border-bottom: 1px solid $tint-darkblue-20;

      .mat-icon {
        &.title-icon {
          width: 36px;
          height: 36px;
        }
      }

      &.dialog-header-with-close-button {
        padding: 0 16px 0 24px;
      }
    }

    vdw-header .wrapper {
      border-bottom: 1px solid $tint-darkblue-20;
    }

    .dialog-content {
      padding: 16px 24px 0 24px;
    }

    mat-dialog-actions {
      margin: 16px 24px;
      padding: 0;
      min-height: initial;
      border-top: 0;

      .mat-mdc-button-base + .mat-mdc-button-base {
        margin-left: 0;
      }
    }
  }

  .alert-dialog {
    width: 480px;

    .mat-mdc-dialog-container {
      --mdc-dialog-container-elevation: 0 0 3px 0 #{$tint-neutral-black-20-transparent}, 0 24px 48px -12px #{$tint-neutral-black-25-transparent};
      --mdc-dialog-container-shape: 8px;

      .mat-mdc-dialog-surface .dialog-content {
        padding: 24px 24px 0;

        h2,
        p {
          color: $neutral-black;
          word-break: break-word;
        }

        a,
        li::marker {
          color: $primary-400 !important;
        }
      }
    }

    @include breakpoint-xsmall() {
      max-width: 100vw !important;
    }
  }

  .basic-dialog-panel {
    height: 527px;
    min-width: 480px;

    @include breakpoint-xsmall() {
      max-width: 100vw !important;
    }

    @include breakpoint-handset-landscape() {
      height: calc(100% - 32px);
    }

    .mat-mdc-dialog-surface > :first-child {
      height: 100%;
    }
  }

  .reposition-dialog {
    position: relative !important;
    max-height: calc(100% - 32px) !important;

    .mat-mdc-dialog-surface {
      height: unset;
      position: unset;
    }
  }

  .reposition-dialog-colored-yarn-set {
    position: static !important;
    max-height: 560px !important;
    max-width: 244px !important;
  }

  .icon-button-dialog-panel {
    .mat-mdc-dialog-container .mat-mdc-dialog-surface {
      border: solid 1px $tint-darkblue-20;
      overflow: hidden;

      > * {
        display: block;
        padding: 24px;
      }
    }
  }

  .large-modal {
    width: 88%;
    min-width: 900px;
    max-width: 1680px !important;
    height: 84%;
    min-height: 480px;
    max-height: 896px;

    .mat-mdc-dialog-surface > :first-child {
      height: 100%;
    }
  }

  .reposition-tooltip {
    position: relative !important;

    .arrow {
      position: absolute;
    }

    .mat-mdc-dialog-surface {
      overflow: hidden;
      position: unset;
    }

    &.arrow-bottom {
      --mdc-dialog-container-elevation: 0 20px 40px #{$tint-darkblue-30-transparent};

      padding-bottom: 19px;

      .arrow {
        transform: rotate(-90deg);
        bottom: 0;
      }
    }

    &.arrow-top {
      --mdc-dialog-container-elevation: 0 -20px 40px #{$tint-darkblue-30-transparent};

      padding-top: 19px;

      .arrow {
        transform: rotate(90deg);
        top: 0;
      }
    }

    &.arrow-left {
      .arrow {
        left: 25px;
      }
    }

    &.arrow-right {
      .arrow {
        right: 25px;
      }
    }
  }

  .cdk-overlay-dark-backdrop {
    background: $tint-black-20-transparent;
  }

  .transparent-backdrop {
    opacity: 0;
  }

  .no-pointer-events-backdrop {
    pointer-events: none;
  }

  .white-backdrop {
    background: $primary-color-white;
  }

  .bms-theme {
    .mat-mdc-dialog-surface {
      box-shadow:
        0 8px 16px -4px $tint-neutral-black-15-transparent,
        0 0 3px 0 $tint-neutral-black-20-transparent !important;
    }
  }

  .no-left-border-radius {
    .mat-mdc-dialog-surface {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  .no-border {
    .mat-mdc-dialog-surface {
      border: none !important;
    }
  }

  .no-padding {
    .mat-mdc-dialog-container .mat-mdc-dialog-surface > * {
      padding: 0;
    }
  }
}
