@use 'sass:map';
@use '@angular/material' as mat;
@use 'vdw-variables.scss' as *;
@use 'components' as *;
@use 'typography';
@use 'font';
@use './utilities/layout';
@use './utilities/icons';
@use './utilities/interaction';
@use '../lib/custom-components/filter-chips/filter-chips.component';
@use './components/grid-theme-api';

$mat-primary: (
  100: $primary-color-vdw-blue,
  500: $primary-color-vdw-blue,
  700: $primary-color-vdw-blue,
  contrast: (
    500: $primary-color-white
  )
);

$warn-palette: map.merge(
  mat.$red-palette,
  (
    500: $functional-color-red
  )
);

$vdw-app-primary: mat.define-palette($mat-primary, 500);
$vdw-app-warn: mat.define-palette($warn-palette);
$vdw-app-typography: mat.define-typography-config(
  $font-family: $mat-font-family
);
$vdw-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $vdw-app-primary,
      accent: $vdw-app-primary,
      warn: $vdw-app-warn
    ),
    typography: $vdw-app-typography,
    density: 0
  )
);

@include mat.core();
@include mat.all-component-typographies($vdw-app-typography);
@include mat.all-component-themes($vdw-app-theme);

* {
  box-sizing: border-box;
  font-family: $mat-font-family;
}

@include components();

:root {
  --mdc-typography-letter-spacing: -0.05px;
  --mdc-typography-body1-letter-spacing: -0.05px;
  --mdc-typography-body2-letter-spacing: -0.05px;
  --mat-menu-item-label-text-tracking: -0.05px;
}

html,
body,
app-root {
  letter-spacing: var(--mdc-typography-letter-spacing);
  height: 100%;
  margin: 0;
  width: 100%;
}
