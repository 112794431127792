@import './colors';
@import './breakpoints';

$mat-font-family: 'Source Sans Pro';

$line-height-for-table-cell: 56px;
$line-height-for-table-header: 20px;
$color-preview-border-radius: 4px;

$breakpoint-gt-xs: 600px;
$breakpoint-gt-sm: 960px;
$breakpoint-gt-md: 1280px;

$breakpoint-lt-sm: $breakpoint-gt-xs - 0.1px;
$breakpoint-lt-md: $breakpoint-gt-sm - 0.1px;
$breakpoint-lt-lg: $breakpoint-gt-md - 0.1px;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-black: 900;

$card-header-height: 68px;
