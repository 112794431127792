@use '../vdw-variables' as *;

@mixin tooltip {
  .mat-mdc-tooltip {
    --mdc-plain-tooltip-container-color: #{$neutral-200};
    --mdc-plain-tooltip-container-shape: 2px;
    --mdc-plain-tooltip-supporting-text-size: 12px;
    --mdc-plain-tooltip-supporting-text-color: #{$neutral-white};
    --mdc-plain-tooltip-supporting-text-font: #{$mat-font-family};
    --mdc-plain-tooltip-supporting-text-tracking: -0.05px;
    --mdc-tooltip-word-break: break-all;

    .mdc-tooltip__surface {
      white-space: pre-line;
      padding: 4px 6px;
      max-width: 172px;
    }

    &.unset-max-width .mdc-tooltip__surface {
      max-width: unset;
    }

    &.unset-max-width-warning {
      .mdc-tooltip__surface {
        background-color: $semantic-warning-400;
      }

      &::after {
        border-bottom-color: $semantic-warning-400 !important;
      }
    }

    &::after {
      width: 0;
      height: 0;
      content: '';
      position: absolute;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 4px solid $neutral-200;
    }

    &.error {
      --mdc-plain-tooltip-container-color: #{$semantic-error-400};

      &::after {
        border-bottom-color: $semantic-error-400;
      }
    }
  }

  .mat-mdc-tooltip-panel-below .mat-mdc-tooltip::after {
    top: -3px;
    right: calc(50% - 4px);
    transform: rotate(0);
  }

  .mat-mdc-tooltip-panel-above .mat-mdc-tooltip::after {
    bottom: -3px;
    right: calc(50% - 4px);
    transform: rotate(180deg);
  }

  .mat-mdc-tooltip-panel-right .mat-mdc-tooltip::after {
    left: -5px;
    top: calc(50% - 2px);
    transform: rotate(270deg);
  }

  .mat-mdc-tooltip-panel-left .mat-mdc-tooltip::after {
    right: -5px;
    top: calc(50% - 2px);
    transform: rotate(90deg);
  }
}
