@use '../vdw-variables' as *;

@mixin menu {
  .mat-mdc-menu-panel {
    --mdc-typography-body1-line-height: inherit;
    --mdc-typography-body1-font-size: 14px;
    --mdc-typography-body1-font-weight: #{$font-weight-regular};
    --mdc-theme-text-primary-on-background: #{$primary-color-dark-blue};

    margin-top: 8px;
    border: solid 1px $tint-darkblue-20;
    background-color: $primary-color-white;

    .mat-mdc-menu-content {
      padding: 0 !important;

      .mat-mdc-menu-item {
        min-height: unset;
        height: 41px;
        border-bottom: solid 1px $tint-darkblue-20;

        &:last-of-type {
          height: 40px;
          border-bottom-width: 0;
        }

        &[disabled] {
          color: $tint-darkblue-50;
          opacity: 1;
        }

        &:hover:enabled {
          background-color: $tint-lightblue-10-transparent !important;
        }
      }
    }
  }

  .bms-theme.mat-mdc-menu-panel {
    --mat-menu-item-icon-color: #{$neutral-black};
    --mat-menu-item-label-text-color: #{$neutral-black};
    --mat-menu-item-label-text-size: 14px;
    --mat-menu-item-label-text-line-height: 16px;

    margin-top: 2px;
    border: none;
    box-shadow:
      0 0 3px 0 $tint-neutral-black-20-transparent,
      0 8px 16px -4px $tint-neutral-black-15-transparent;

    .mat-mdc-menu-content .mat-mdc-menu-item {
      height: 32px;
      border-bottom: none;
      padding: 6px 8px;

      .mat-icon {
        margin-right: 4px;
        width: 16px;
        height: 16px;

        svg path {
          fill: var(--mat-menu-item-icon-color);
        }
      }

      .mat-mdc-menu-ripple {
        display: none;
      }

      &:hover {
        --mat-menu-item-icon-color: #{$primary-400};
        --mat-menu-item-label-text-color: #{$primary-400};

        background-color: $primary-900 !important;
      }
    }
  }
}
