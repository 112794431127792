@use 'variables' as *;

.printable {
  display: none;
}

@media print {
  body {
    background: $primary-color-white !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  app-root,
  .cdk-overlay-container,
  .overlay-container {
    display: none !important;
  }

  .printable {
    display: block;
  }
}
