@use 'variables' as *;
@use 'components' as *;
@use 'external-svg-styles';
@use 'mobile';
@use 'print';
@use 'skeleton';
@use 'vdw-all';
@include components();

*:focus {
  outline: none;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
}

a:hover {
  text-decoration: none;
}

span[matprefix] {
  padding-right: 5px;
}

[hidden] {
  display: none !important;
}

.input-row {
  margin-bottom: 15px;
}

.header-title {
  margin-top: 0;
}

.custom-tooltip {
  text-align: center;
}

body {
  touch-action: manipulation;
  background: $tint-vdw-blue-6;

  @include breakpoint-xsmall() {
    overflow: hidden;
  }
}

.mat-mdc-card mat-divider.mat-divider {
  position: initial;
}

.mat-toolbar,
.fixed-toolbar-actions {
  background-color: $primary-color-white;
  height: $toolbar-actions-height !important;
}

.fixed-toolbar-actions {
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 1;
  box-shadow: 0 -2px 8px $tint-vdw-blue-10-transparent;
}

.main-container {
  &.collapsed {
    .fixed-toolbar-actions {
      width: calc(100% - 72px);

      @include breakpoint-xsmall() {
        width: 100%;
      }
    }
  }

  .fixed-toolbar-actions {
    width: calc(100% - #{$side-navigation-width});
    transition: width 200ms;
  }
}

.draggable-item {
  background-color: $primary-color-white;
  border: solid 1px $tint-darkblue-20;
  border-radius: 3px;
  height: 44px;
  margin: 16px 0;
  padding: 10px 8px;
  position: relative;

  &.selected {
    border: solid 1px $tint-darkblue-20;
    box-shadow: 0 14px 9px -8px $tint-darkblue-6;
  }

  .position {
    color: $primary-color-dark-blue;
    font-size: 18px;
    font-weight: 600;
  }

  &:hover {
    border: solid 1px $tint-darkblue-20;
    box-shadow: 0 14px 9px -8px $tint-darkblue-6;
    cursor: pointer;
  }
}

.drag-handle {
  &:hover {
    cursor: move;
  }
}

.drag-preview {
  width: 280px;
  height: 56px;
  padding: 13px 16px;
  background-color: $primary-color-white;
  box-shadow: 0 16px 48px $tint-vdw-blue-20-transparent;
  border-radius: 4px;
}

.selected-indicator {
  background-color: $primary-color-vdw-blue;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  height: 44px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 5px;
}

.form-scrollable-content {
  position: relative;
  overflow: auto;
  height: calc(100% - #{$card-header-height});

  @media (min-width: $breakpoint-gt-xs) {
    &.invalid-form-scrollable-content {
      height: calc(100% - #{$card-header-height-with-warning});
    }
  }
}

.full-max-height-with-toolbar {
  max-height: calc(100% - #{$toolbar-actions-height});
}

.half-height-with-gap {
  max-height: calc(50% - 8px);
}

.label-tooltip {
  position: absolute;
  top: -24px;
  left: 0;
  width: 100%;
  height: 20px;
}

.svg-wrapper > svg {
  border: 1px solid $tint-darkblue-20;
  border-radius: 3px;
  max-height: 127px;
  max-width: 100%;
}

.cursor-zoom-in {
  cursor: zoom-in;
}

.b-neutral-950 {
  background-color: $neutral-950;
}
