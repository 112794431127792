@use 'variables' as *;

.skeleton-wrapper {
  &.skeleton {
    pointer-events: none;

    .mat-mdc-form-field,
    .link-form-input,
    :not(.mobile-header) > button,
    .header-actions,
    .mat-expansion-panel,
    .mat-expansion-panel-header,
    .mat-mdc-card-avatar > mat-icon,
    .mat-mdc-card-title > span,
    .mat-mdc-card-title > h3,
    .checkbox-skeleton .mat-mdc-checkbox,
    .radio-skeleton label,
    .mat-select-placeholder,
    .mat-mdc-slider,
    .color-picker,
    .color-picker-label,
    :not(.mobile-header) > .title,
    .ag-header-container,
    .disabled-form-input > *,
    .list-skeleton-wrapper > *,
    .machine-type-wrapper > *,
    .text-skeleton-wrapper > *,
    .tab-skeleton-wrapper > * {
      opacity: 0;
    }

    .form-field-skeleton-wrapper,
    .form-field-without-title-skeleton-wrapper,
    .button-skeleton-wrapper,
    .mat-mdc-card-avatar,
    .mat-mdc-card-title,
    .checkbox-skeleton,
    .radio-skeleton,
    .text-skeleton-wrapper,
    .tab-skeleton-wrapper,
    .color-picker-skeleton-wrapper,
    .title-skeleton-wrapper,
    .video-skeleton-wrapper,
    .icon-skeleton-wrapper,
    .list-skeleton-wrapper {
      position: relative;
      animation: fade-in-fade-out 1.8s infinite !important;
    }

    .form-field-skeleton-wrapper {
      &::before {
        background-color: $tint-vdw-blue-10;
        content: ' ';
        height: 8px;
        left: 0;
        position: absolute;
        top: 7px;
        width: 60px;
      }

      &::after {
        background-color: $tint-vdw-blue-10;
        content: ' ';
        height: 12px;
        left: 0;
        position: absolute;
        top: 34px;
        width: 100%;
      }
    }

    .title-skeleton-wrapper {
      &::before {
        background-color: $tint-vdw-blue-10;
        content: ' ';
        height: 8px;
        left: 0;
        position: absolute;
        top: 7px;
        width: 60px;
      }
    }

    .icon-skeleton-wrapper {
      &::before {
        background-color: $tint-vdw-blue-10;
        content: ' ';
        height: 24px;
        width: 24px;
        position: absolute;
        left: 0;
        top: 7px;
      }
    }

    .list-skeleton-wrapper {
      min-height: 48px;

      &::before {
        background-color: $tint-vdw-blue-10;
        content: ' ';
        height: 12px;
        left: 0;
        position: absolute;
        top: 7px;
        width: 100%;
      }

      &::after {
        background-color: $tint-vdw-blue-10;
        content: ' ';
        height: 12px;
        left: 0;
        position: absolute;
        top: 34px;
        width: 100%;
      }
    }

    .color-picker-skeleton-wrapper {
      &::before {
        background-color: $tint-vdw-blue-10;
        content: ' ';
        height: 8px;
        left: 0;
        position: absolute;
        top: 7px;
        width: 60px;
      }

      &::after {
        background-color: $tint-vdw-blue-10;
        content: ' ';
        height: 275px;
        left: 0;
        position: absolute;
        top: 28px;
        width: 275px;
      }
    }

    .form-field-without-title-skeleton-wrapper {
      &::after {
        background-color: $tint-vdw-blue-10;
        content: ' ';
        height: 12px;
        left: 0;
        position: absolute;
        top: 34px;
        width: 100%;
      }
    }

    .button-skeleton-wrapper {
      &.raised-btn {
        &::after {
          background-color: $tint-vdw-blue-10;
        }
      }

      &.standard-btn {
        &::after {
          border: 1px solid $tint-vdw-blue-10;
        }
      }

      &::after {
        border-radius: 4px;
        content: ' ';
        height: 44px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    .video-skeleton-wrapper {
      &::before {
        background-color: $tint-vdw-blue-10;
        content: ' ';
        height: 326.25px;
        left: 0;
        position: absolute;
        top: 0;
        width: 580px;
        border-radius: 6px;
      }
    }

    .mat-mdc-card-avatar {
      &::after {
        background-color: $tint-vdw-blue-10;
        border-radius: 50%;
        content: ' ';
        height: 100%;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }

    .mat-mdc-card-title {
      &::before {
        background-color: $tint-vdw-blue-10;
        content: ' ';
        height: 12px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
      }

      &.title-amount {
        &::after {
          background-color: $tint-vdw-blue-10;
          content: ' ';
          height: 8px;
          left: 0;
          position: absolute;
          top: 28px;
          width: 40px;
        }

        &::before {
          top: 6.5px;
          transform: unset;
        }
      }
    }

    .checkbox-skeleton,
    .radio-skeleton {
      span {
        line-height: 22px;
      }

      &::after {
        background-color: $tint-vdw-blue-10;
        content: ' ';
        height: 8px;
        left: 22px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: calc(100% - 22px);
      }
    }

    .text-skeleton-wrapper {
      &::after {
        background-color: $tint-vdw-blue-10;
        content: ' ';
        height: 12px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
      }
    }

    .tab-skeleton-wrapper {
      &::after {
        background-color: $tint-vdw-blue-10;
        content: ' ';
        height: 14px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 48px;
        margin: 0 24px;
      }
    }
  }

  @keyframes fade-in-fade-out {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }
}
