@use '../vdw-variables' as *;

.ag-theme-vdw {
  height: 100%;
}

ag-grid-angular {
  flex: 1 1 auto;
}

.ag-grid-compact-view {
  --ag-row-height: 24px;
}

.ag-grid-large-view {
  --ag-row-height: 48px;
}

.ag-grid-form-view {
  --ag-row-height: 88px;
}

.ag-header-cell-label,
.ag-header-group-cell-label {
  --ag-spacing: 4px;
}

.ag-cell.cell-no-x-padding {
  --ag-cell-horizontal-padding: 0;
}

.ag-row.row-group-class.ag-row-group-expanded,
.ag-row.row-group-class.ag-row-level-1,
.ag-row.row-group-class.ag-row-level-2 {
  background-color: $neutral-950;
}

.ag-auto-grow-grid-height {
  .ag-root-wrapper-body {
    height: auto;
  }
}

.ag-sort-indicator-icon {
  --ag-spacing: 0;
}

.ag-layout-auto-height {
  .ag-center-cols-viewport {
    min-height: var(--ag-row-height);
  }
}

.ag-row.ag-row-level-1 {
  --ag-row-border: 0;

  &.last-expanded-row {
    border-bottom: 1px solid $neutral-850;
  }
}

.ag-row-last {
  border-bottom: 1px solid $neutral-850;
}

.ag-cell {
  align-content: center;

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;

    .ag-group-value {
      margin-left: auto;
    }
  }

  &.left {
    text-align: left;
  }

  .density {
    margin-left: 4px;
  }

  &:has(mat-form-field) {
    padding: unset !important;
  }
}

.ag-body-horizontal-scroll {
  order: 2;
}

.ag-pinned-left-header .ag-header-cell,
.ag-pinned-right-header .ag-header-cell {
  --ag-header-column-border: none;
}

.ag-cell.ag-cell-flex-center .ag-selection-checkbox {
  visibility: hidden;
  margin: 0;
}

.ag-row-hover .ag-cell.ag-cell-flex-center .ag-selection-checkbox,
.ag-row-selected .ag-cell.ag-cell-flex-center .ag-selection-checkbox {
  visibility: visible;
}

.ag-menu {
  width: 240px;

  &:has(.ag-icon-tick) {
    width: 120px;
    min-width: 120px !important;
  }
}

.ag-horizontal-left-spacer,
.ag-horizontal-right-spacer {
  visibility: hidden;
}

.ag-cell-wrapper {
  height: 100%;
}

.ag-checkbox-input-wrapper {
  width: 16px;
  height: 16px;

  &::before {
    content: '';
    background: transparent center / contain no-repeat;
    position: absolute;
    inset: 0;
    background-image: url('../../assets/solid-icons/solid-checkbox-unchecked.svg');
  }

  &.ag-checked {
    &::before {
      background-image: url('../../assets/solid-icons/solid-checkbox-checked.svg');
    }
  }

  &.ag-disabled {
    &::before {
      background-image: url('../../assets/solid-icons/solid-checkbox-checked-disabled.svg');
    }
  }

  &.ag-indeterminate {
    &::before {
      background-image: url('../../assets/solid-icons/solid-checkbox-indeterminate.svg');
    }
  }

  input {
    opacity: 0;
    height: 100%;
    -webkit-appearance: none;
    min-width: 100%;
    margin: 0;

    &:focus {
      outline: 0;
    }
  }
}

.ag-radio-button-input-wrapper {
  width: 16px;
  height: 16px;

  .ag-radio-button-label {
    line-height: 32px;
  }

  input {
    opacity: 0;
    width: 16px;
    height: 16px;
    margin: 0;
  }

  &.ag-checked::before {
    background-image: url('../../assets/solid-icons/solid-radio-button-checked.svg');
  }

  &::before {
    content: '';
    background: transparent center / contain no-repeat;
    position: absolute;
    inset: 0;
    background-image: url('../../assets/solid-icons/solid-radio-button-unchecked.svg');
    width: 16px;
    height: 16px;
  }
}

input {
  font-family: $mat-font-family;
  width: 100%;
  font-size: 14px;
  line-height: 16px;

  &::placeholder {
    color: $neutral-700;
  }
}

.ag-floating-filter-input {
  .ag-text-field-input-wrapper {
    &::before {
      display: none;
    }
  }

  .ag-input-field-input {
    border: none;
    background-color: transparent;
    padding: 1px 2px !important;

    &::placeholder {
      color: $neutral-600;
    }
  }
}

.ag-filter-body {
  --ag-widget-vertical-spacing: 0;

  .ag-input-wrapper {
    input {
      padding-left: 8px !important;
    }

    &::before {
      display: none;
    }
  }
}

.ag-filter-body,
.ag-mini-filter,
.ag-panel {
  input.ag-text-field-input {
    background-color: $neutral-950;
    border: 1px solid $neutral-800;
    padding: 8px;
    border-radius: 4px;

    &:focus {
      border: 1px solid $primary-color-vdw-blue;
    }
  }
}

.ag-filter-active {
  .ag-icon-filter {
    clip-path: none;
  }

  &::after {
    display: none;
  }
}

.ag-icon {
  &::before {
    content: '';
    mask-size: 16px;
    height: 16px;
    background: $neutral-600;
    display: flex;
  }

  &.ag-icon-menu-alt::before {
    mask-image: url('../../assets/solid-icons/solid-more-vert.svg');
  }

  &.ag-icon-loading::before {
    mask-image: url('../../assets/solid-icons/progress-activity.svg');
    background: $neutral-black;
  }

  &.ag-icon-cross::before {
    mask-image: url('../../assets/solid-icons/solid-close.svg');
    background: $neutral-black;
  }

  &.ag-icon-small-down::before {
    mask-image: url('../../assets/solid-icons/solid-keyboard-arrow-down.svg');
    background: $neutral-black;
  }

  &.ag-icon-grip::before,
  &.ag-icon-arrows::before {
    mask-image: url('../../assets/solid-icons/solid-drag-indicator.svg');
    background: $neutral-black;
  }

  &-tree-open::before {
    mask-image: url('../../assets/solid-icons/solid-keyboard-arrow-down.svg');
    background: $neutral-400;
  }

  &-tree-closed::before {
    mask-image: url('../../assets/solid-icons/solid-keyboard-arrow-up.svg');
    background: $neutral-400;
  }

  &.ag-icon-filter::before {
    mask-image: url('../../assets/solid-icons/solid-filter-list.svg');
  }

  &.ag-icon-asc::before {
    mask-image: url('../../assets/solid-icons/solid-arrow-upward-alt.svg');
    background: $neutral-black;
  }

  &.ag-icon-not-allowed::before {
    mask-image: url('../../assets/solid-icons/solid-block.svg');
    background: $neutral-black;
  }

  &.ag-icon-desc::before {
    mask-image: url('../../assets/solid-icons/solid-arrow-downward-alt.svg');
    background: $neutral-black;
  }

  &.ag-icon-none::before {
    mask-image: url('../../assets/solid-icons/solid-swap-vert.svg');
    background: $neutral-black;
  }

  &.ag-icon-width::before {
    mask-image: url('../../assets/solid-icons/solid-width.svg');
    background: $neutral-black;
  }

  &.ag-icon-pin::before {
    mask-image: url('../../assets/solid-icons/solid-push-pin.svg');
    background: $neutral-black;
  }

  &.ag-icon-visibility::before {
    mask-image: url('../../assets/solid-icons/solid-visibility.svg');
    background: $neutral-black;
  }

  &.ag-icon-visibility-off::before {
    mask-image: url('../../assets/solid-icons/solid-visibility-off.svg');
    background: $neutral-black;
  }

  &.ag-icon-small-right::before {
    mask-image: url('../../assets/solid-icons/solid-keyboard-arrow-right.svg');
    background-color: $neutral-600;
  }
}

.ag-menu-list {
  padding-left: 8px;
  padding-right: 8px;
  font-family: $mat-font-family;

  .ag-menu-option {
    font-weight: $font-weight-regular;
    gap: 4px;
    display: flex;
    padding: 6px 6px 6px 8px;
    border-radius: 4px;
    align-items: center;

    .ag-menu-option-icon,
    .ag-menu-option-part {
      --ag-spacing: 0;
    }

    .ag-menu-option-text {
      padding-top: 2px;
      padding-bottom: 2px;
      flex: 1;
    }

    &.ag-menu-option-active,
    &:has(.ag-icon-tick) {
      background-color: $primary-900;
      color: $primary-400;

      .ag-menu-option-part .ag-icon::before {
        background: $primary-400;
      }

      .ag-menu-option-part .ag-icon.ag-icon-small-right {
        color: $primary-400;
      }
    }

    .ag-menu-option-icon:empty,
    .ag-menu-option-icon:has(.ag-icon-columns),
    .ag-menu-option-icon:has(.ag-icon-tick) {
      display: none;
    }
  }
}

.ag-picker-field-wrapper {
  border: 1px solid $neutral-800;
  background-color: $neutral-950;
  border-radius: 4px;

  &:focus-within {
    border: 1px solid $neutral-800;
  }
}

.ag-mini-filter,
.ag-panel {
  .ag-text-field-input-wrapper {
    &::before {
      display: none;
    }

    &::after {
      position: absolute;
      display: block;
      width: 16px;
      height: 16px;
      background-image: url('../../assets/solid-icons/solid-search.svg');
      background-position: 50% 50%;
      background-size: contain;
      content: '';
      left: 8px;
      opacity: 1;
      margin-left: 0;
    }
  }
}

.ag-filter-separator {
  border-top: 1px solid $neutral-900;
}

.ag-filter-condition {
  margin-top: 8px;
}

.ag-filter-apply-panel {
  .ag-filter-apply-panel-button {
    --ag-spacing: 0;

    line-height: 16px;
    border: 1px solid $primary-700;
    color: $primary-300;
    padding: 8px 10px 8px 10px;
    font-weight: $font-weight-semibold;

    &:hover {
      --ag-row-hover-color: $tint-lightblue-10;
    }
  }
}

.ag-set-filter-list {
  padding: 0 8px;

  .ag-virtual-list-item {
    color: $primary-color-dark-blue;
    border-radius: 4px;

    &:hover {
      background-color: $primary-900;
    }
  }
}

.ag-panel {
  padding: 8px;

  .ag-panel-title-bar {
    padding: 8px 8px 16px;
  }

  .ag-panel-content-wrapper {
    padding-top: 8px;
  }

  .ag-column-select {
    .ag-column-select-column {
      gap: 4px;
      height: 28px;
    }

    .ag-column-select-header {
      --ag-widget-horizontal-spacing: 8px;
    }

    .ag-column-select-list {
      padding-top: 8px;
    }

    .ag-icon-grip::before {
      mask-image: url('../../assets/solid-icons/solid-drag-indicator.svg');
      color: $neutral-700;
    }

    .ag-icon-cross::before {
      mask-image: url('../../assets/solid-icons/solid-close.svg');
      color: $neutral-700;
    }
  }
}

.ag-row-hover .aggrid-editable {
  &.invalid {
    border-radius: 4px;
    border-color: $semantic-error-400;
  }

  &:hover {
    border: 1px solid $neutral-700;
    padding-left: calc(var(--ag-cell-horizontal-padding) - 2px);
    padding-right: calc(var(--ag-cell-horizontal-padding) - 2px);
  }
}

.ag-cell-inline-editing {
  --ag-input-focus-border: 1px solid $primary-400;

  border-radius: 4px;
  height: 100%;

  .ag-cell-wrapper > * {
    width: 100%;
  }

  &:has(.mat-mdc-form-field.mat-form-field-invalid) {
    border-radius: 4px;

    --ag-input-focus-border: 1px solid $semantic-error-400;
  }
}

ag-grid-angular.loading {
  .ag-full-width-container {
    height: 100% !important;
  }

  .ag-row-loading {
    height: 100% !important;
    display: flex;
    justify-content: center;
    background: $primary-color-white;
    border: 0;
  }

  .ag-body-horizontal-scroll {
    visibility: hidden;
  }
}
